import React from "react";
import { useContext } from "react";
import ReviewContext from "../../../context/ReviewContext";

function TemplateInformation() {
  const { templateDetails } = useContext(ReviewContext);

  return (
    <div className="flex min-w-full h-full flex-1 bg-gray1 rounded-lg p-2">
      {templateDetails ? (
        <div className="flex min-w-full min-h-full flex-col space-y-3 overflow-y-auto bg-white p-2 rounded-lg">
          <div>{templateDetails.description}</div>

          <div className="flex flex-col space-y-1">
            <h1 className="font-poppins text-xl">Categories</h1>
            {templateDetails.categories.map((category) => (
              <div key={category.name}>
                <h2>- {category.name}</h2>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <span className="flex min-w-full h-full flex-1 items-center justify-center align-middle font-poppins text-gray4 italic text-center">
          Select a template to get started...
        </span>
      )}
    </div>
  );
}

export default TemplateInformation;
