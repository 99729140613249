import React from "react";
import TemplateTypeDropdown from "../TemplateTypeDropdown";
import ButtonTooltip from "../../ui/ButtonTooltip";
import { useContext } from "react";
import ReviewContext from "../../../context/ReviewContext";
import { PrimarySections } from "../../../constants/Constants";

function ReviewInformation() {
  const {
    templateDetails,
    currentStepHandler,
    revieweeName,
    revieweeNameHandler,
    revieweeJobTitle,
    revieweeJobTitleHandler,
    reviewCategoriesHandler,
    selectedCategoryNameHandler,
  } = useContext(ReviewContext);

  return (
    <div className="font-poppins text-black p-10 flex flex-col w-full h-full space-y-10 items-center justify-center align-middle mx-auto bg-gray1 rounded-lg">
      <div className="flex h-full w-full flex-1">
        <div className="grid grid-cols-2 grid-rows-3 gap-4 items-center justify-center">
          <span className="text-2xl">Template Type</span>
          <TemplateTypeDropdown />
          <span className="text-2xl">Reviewee Name</span>
          <textarea
            id="revieweeName"
            className="bg-white border-[1px] border-gray4 rounded-lg px-2 py-1 resize-none focus:outline-none"
            rows={1}
            value={revieweeName}
            placeholder="Enter a name"
            onChange={(e) => {
              revieweeNameHandler(e.target.value);
            }}
          ></textarea>
          <span className="text-2xl">Reviewee Job Title</span>
          <textarea
            id="revieweeJobTitle"
            className="bg-white border-[1px] border-gray4 rounded-lg px-2 py-1 resize-none focus:outline-none"
            rows={1}
            value={revieweeJobTitle}
            placeholder="Enter a job title"
            onChange={(e) => {
              revieweeJobTitleHandler(e.target.value);
            }}
          ></textarea>
        </div>
      </div>
      <div className="flex flex-row w-full max-h-[10%] align-middle justify-end p-2">
        <ButtonTooltip text="Click to start the review.">
          <button
            disabled={
              revieweeName.length === 0 ||
              revieweeJobTitle.length === 0 ||
              !templateDetails
            }
            onClick={() => {
              let categories = {};
              templateDetails.categories.forEach((category, index) => {
                index === 0 && selectedCategoryNameHandler(category.name);
                categories[category.name] = {
                  index: index,
                  categoryReview: null,
                  categoryReviewScore: null,
                  categoryDetails: null,
                  categoryAnswers: null,
                  categoryAssistantMessages: null,
                  categoryAssistantThreadId: null,
                };
              });
              reviewCategoriesHandler(categories);
              currentStepHandler(PrimarySections.ReviewQuestions);
            }}
            className="min-w-[25%] text-center inline-flex items-center justify-center whitespace-nowrap p-[7px] md:p-3 px-2 md:px-8 text-[19px] text-white font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
          >
            Start Review
          </button>
        </ButtonTooltip>
      </div>
    </div>
  );
}

export default ReviewInformation;
