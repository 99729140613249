import ButtonTooltip from "./ButtonTooltip";
import React from "react";
import PropTypes from "prop-types";

function FooterActionButton({ text, action, disabled = false, tooltip }) {
  return (
    <ButtonTooltip text={tooltip}>
      <button
        disabled={disabled}
        onClick={action}
        className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal float-right"
      >
        {text}
      </button>
    </ButtonTooltip>
  );
}

FooterActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string.isRequired,
};

export default FooterActionButton;
