import * as Tooltip from "@radix-ui/react-tooltip";
import React from "react";
import PropTypes from "prop-types";

function ButtonTooltip({ children, text, side, maxWidth }) {
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={600}>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className={`max-w-[${maxWidth}px]  text-gray5 font-poppins select-none rounded-[4px] bg-gray1 px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]`}
            side={side}
          >
            {text}
            <Tooltip.Arrow className="fill-gray1" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

ButtonTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  side: PropTypes.string,
  maxWidth: PropTypes.number,
};

ButtonTooltip.defaultProps = {
  side: "bottom",
  maxWidth: 300,
};

export default ButtonTooltip;
