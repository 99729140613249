import { useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import NoteItem from "./NoteItem";
import NoteTypeDropdown from "./NoteTypeDropdown";
import ButtonTooltip from "../ui/ButtonTooltip";
import BotContext from "../../context/BotContext";
import React from "react";

function InputNotes() {
  const { notesList, addNoteHandler, removeNote, updateNote } =
    useContext(BotContext);
  const [newNote, setNewNote] = useState("");
  const [newNoteType, setNewNoteType] = useState("Strength");

  function noteHandler(value) {
    setNewNote(value);
  }

  return (
    <div className="flex flex-col h-full w-full py-2">
      <div className="flex flex-col w-full h-[20%] border-b-[14px] border-gray1">
        <textarea
          autoFocus
          id="editor"
          className="block w-full px-2 text-[22px] font-poppins text-black bg-white border-0 focus:outline-none resize-none selection:bg-royal
selection:bg-opacity-30"
          rows={2}
          value={newNote}
          onChange={(e) => {
            noteHandler(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (newNote.length !== 0) {
                e.preventDefault();
                addNoteHandler({
                  note: newNote,
                  type: newNoteType,
                  id: uuidv4(),
                });
                noteHandler("");
              }
            }
          }}
          placeholder={"Enter notes"}
        ></textarea>
        <div className=" w-full bg-white pr-2 pb-1">
          <div className="flex flex-row space-x-2 float-right">
            <NoteTypeDropdown
              noteType={newNoteType}
              valueChangeHandler={setNewNoteType}
            />
            <ButtonTooltip text="Click to add a feedback note.">
              <button
                disabled={newNote.length === 0}
                onClick={() => {
                  addNoteHandler({
                    note: newNote,
                    type: newNoteType,
                    id: uuidv4(),
                  });
                  noteHandler("");
                }}
                className="h-[30px] text-[15px] px-4 py-[3px] text-center inline-flex items-center text-white font-poppins bg-royal rounded-full baseline hover:bg-deeproyal float-right"
              >
                Add
              </button>
            </ButtonTooltip>
          </div>
        </div>
      </div>

      <div className="flex flex-col overflow-auto w-full h-[80%]">
        {notesList.length === 0 ? (
          <div className=" w-full h-full text-gray5 italic text-center font-poppins">
            Add a note to get started...
          </div>
        ) : (
          notesList.map((note) => (
            <NoteItem
              noteData={note}
              key={note.id}
              updateNote={updateNote}
              removeNote={removeNote}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default InputNotes;
