import toast from "react-hot-toast";
import { MdOutlineClose } from "react-icons/md";
import React from "react";

export function ToastNotification(text) {
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-[400px] bg-gray1 shadow-lg rounded-lg pointer-events-auto flex flex-col ring-1 ring-royal ring-opacity-5 border-b-2 border-royal`}
      >
        <div className="h-[29px] w-[30px] mt-1 mr-2 self-end">
          <button onClick={() => toast.dismiss(t.id)}>
            <MdOutlineClose className="h-[23px] w-[23px] text-royal hover:text-deeproyal" />
          </button>
        </div>

        <span className="flex-1 mx-2 mb-2 text-[15px] text-gray5 font-poppins">
          {text}
        </span>
      </div>
    ),
    { duration: 10000 }
  );
}
