import * as Tooltip from "@radix-ui/react-tooltip";
import { useContext, useEffect } from "react";
import BotContext from "../../context/BotContext";
import { IdentifySkills } from "../../helpers/AnalyticsHelpers";
import { ToastNotification } from "../ui/ToastNotifications";
import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../ui/LoadingSpinner";
import useToggle from "../../hooks/useToggle";

function SkillsAnalytics() {
  const {
    optimizedText,
    skillsToHighlightHandler,
    strengths,
    setStrengths,
    weaknesses,
    setWeaknesses,
    neutrals,
    setNeutrals,
    outputLoadingHandler,
  } = useContext(BotContext);

  const [showOutput, setShowOutput] = useToggle(false);

  useEffect(() => {
    if (optimizedText.length > 0) {
      buildSkillsAnalytics();
    }
  }, []);

  async function buildSkillsAnalytics() {
    try {
      outputLoadingHandler("Analyzing skills...", true);
      setStrengths([]);
      setWeaknesses([]);
      setNeutrals([]);

      let skills = await IdentifySkills(optimizedText);
      if (skills.length > 0) {
        skillsToHighlightHandler(skills);
      }

      let output_strengths = [];
      let output_weaknesses = [];
      let output_neutrals = [];

      skills.forEach((skill) => {
        if (skill.rating === "strength") {
          output_strengths.push(skill);
        } else if (skill.rating === "weakness") {
          output_weaknesses.push(skill);
        } else {
          output_neutrals.push(skill);
        }
      });

      setStrengths(output_strengths);
      setWeaknesses(output_weaknesses);
      setNeutrals(output_neutrals);
    } catch (error) {
      console.log("Error analyzing skills: ", error);
      ToastNotification(
        "Something went wrong while analyzing skills. Please try again."
      );
    }
    outputLoadingHandler("", false);
    setShowOutput(true);
  }

  return (
    <div className="flex h-full w-full flex-1 relative font-poppins overflow-y-auto">
      {!showOutput && (
        <div className="absolute h-full w-full">
          <LoadingSpinner />
        </div>
      )}
      {showOutput && (
        <div className="flex p-4 h-full w-full flex-1 relative font-poppins flex-col space-y-8 selection:text-black">
          {typeof strengths != "undefined" && strengths.length > 0 ? (
            <CategoryDiv name="Strengths" skills={strengths} />
          ) : (
            <CategoryDiv
              name="Strengths"
              skills={[{ skill: "Not found", references: ["None"] }]}
            />
          )}
          {typeof weaknesses != "undefined" && weaknesses.length > 0 ? (
            <CategoryDiv name="Weakneses" skills={weaknesses} />
          ) : (
            <CategoryDiv
              name="Challenges"
              skills={[{ skill: "Not found", references: ["None"] }]}
            />
          )}
          {typeof neutrals != "undefined" && neutrals.length > 0 ? (
            <CategoryDiv name="Neutral" skills={neutrals} />
          ) : (
            <CategoryDiv
              name="Neutral"
              skills={[{ skill: "Not found", references: ["None"] }]}
            />
          )}
        </div>
      )}
    </div>
  );
}

function CategoryDiv({ name, skills }) {
  return (
    <div className="flex flex-col pb-1">
      <p className="text-[22px] font-poppins">{name}</p>
      <div className="flex flex-col space-y-1 pl-2">
        {skills.map((skill, index) => (
          <SkillSpan
            key={index}
            index={index}
            skill={skill.skill}
            references={skill.references}
          />
        ))}
      </div>
    </div>
  );
}

CategoryDiv.propTypes = {
  name: PropTypes.string.isRequired,
  skills: PropTypes.array.isRequired,
};

function SkillSpan({ skill, references, index }) {
  return (
    <div key={index} className="flex">
      <Tooltip.Provider>
        <Tooltip.Root delayDuration={600}>
          <Tooltip.Trigger asChild>
            <span className="font-poppins text-[18px] capitalize">
              - {skill}
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="max-w-[300px]  text-gray5 font-poppins select-none rounded-[4px] bg-gray1 px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
              side="right"
            >
              {references.join(", ")}
              <Tooltip.Arrow className="fill-gray1" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </div>
  );
}

SkillSpan.propTypes = {
  skill: PropTypes.string.isRequired,
  references: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

export default SkillsAnalytics;
