import { useState, useRef, useEffect } from "react";
import OutputTextLens from "./OutputTextLens";
import { useContext } from "react";
import BotContext from "../../context/BotContext";
import LoadingSpinner from "../ui/LoadingSpinner";
import React from "react";

const OutputTextArea = () => {
  const {
    isOutputLoading,
    optimizedText,
    optimizedTextHandler,
    highlightedBiasText,
    highlightedSkillsText,
  } = useContext(BotContext);

  const textareaRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    const ref = textareaRef.current;

    if (ref) {
      ref.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref) {
        ref.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    textareaRef.current?.focus();
  }, [isOutputLoading]);

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState("100%");

  const handleScroll = () => {
    try {
      setTop(-textareaRef.current.scrollTop);
      setLeft(-textareaRef.current.scrollleft); //TODO Fix: `NaN` is an invalid value for the `left` css style property.
      setWidth(`${textareaRef.current.clientWidth}px`);
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const styles = {
    textarea: [
      "h-full",
      "bg-white",
      "px-2",
      "w-full",
      "z-10",
      "[-webkit-text-fill-color:_transparent]",
      "focus:outline-none",
      "resize-none",
    ].join(" "),
  };

  return (
    <div className="w-full h-full relative">
      {isOutputLoading && (
        <div className="absolute top-50 left-50 w-full h-full">
          <LoadingSpinner />
        </div>
      )}
      <textarea
        value={optimizedText}
        onChange={(e) => optimizedTextHandler(e.target.value)}
        placeholder="Create feedback to get started..."
        disabled={optimizedText === "" || isOutputLoading}
        ref={textareaRef}
        className={styles.textarea}
        id="output-textarea"
      ></textarea>
      <OutputTextLens
        highlightedText={
          optimizedText === ""
            ? "Create feedback to get started..."
            : optimizedText
        }
        overlayRef={overlayRef}
        topPosition={top}
        leftPosition={left}
        width={width}
        textColor={optimizedText === "" ? "#999" : "black"}
      />
      {highlightedBiasText && (
        <OutputTextLens
          highlightedText={highlightedBiasText}
          overlayRef={overlayRef}
          topPosition={top}
          leftPosition={left}
          width={width}
          textColor="transparent"
        />
      )}
      {highlightedSkillsText && (
        <OutputTextLens
          highlightedText={highlightedSkillsText}
          overlayRef={overlayRef}
          topPosition={top}
          leftPosition={left}
          width={width}
          textColor="transparent"
        />
      )}
    </div>
  );
};

export default OutputTextArea;
