import { useState, useEffect, useRef, useContext } from "react";
import React from "react";
import { MdSend } from "react-icons/md";
import ReviewContext from "../../../context/ReviewContext";
import { HttpPostRequest } from "../../../helpers/HttpPostRequest";
import { ToastNotification } from "../../ui/ToastNotifications";
import PropTypes from "prop-types";
import LoadingSpinner from "../../ui/LoadingSpinner";
import useToggle from "../../../hooks/useToggle";
import FooterActionButton from "../../ui/FooterActionButton";
import { AiCompanionStatuses } from "../../../constants/Constants";
import { GetCategoryReviewScore } from "../../../helpers/GetCategoryReviewScore";

function ChatWidget() {
  const {
    AddReviewMessageHandler,
    revieweeName,
    revieweeJobTitle,
    categoryDetails,
    categoryReview,
    categoryReviewHandler,
    categoryAssistantMessages,
    categoryAssistantMessagesHandler,
    categoryAssistantThreadId,
    categoryAssistantTyping,
    categoryAssistantTypingHandler,
    categoryReviewScoreHandler,
    userId,
    aiCompanionStatusHandler,
    categoryAssistantReset,
    aiCompanionMessagesHandler,
  } = useContext(ReviewContext);

  const [input, setInput] = useState("");
  const [isAssistantLoading, SetIsAssistantLoading] = useToggle(false);
  const messagesRef = useRef();
  messagesRef.current = categoryAssistantMessages;
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const assistantMessageHandlerRef = useRef();
  assistantMessageHandlerRef.current = assistantMessageHandler;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollTo(0, messagesEndRef.current?.scrollHeight);
  };

  useEffect(() => {
    const assistantHandler = async (value) => {
      if (messagesRef.current.length === 0) {
        await assistantMessageHandlerRef.current(value, true);
      } else {
        await assistantMessageHandlerRef.current(value);
      }
    };

    if (messagesRef.current.length === 0) {
      assistantHandler("");
    } else {
      if (messagesRef.current.at(-1)?.role === "system") {
        aiCompanionStatusHandler(AiCompanionStatuses.Generating);
        assistantHandler("GENERATE FEEDBACK");
      }
    }
    scrollToBottom();
  }, [categoryAssistantMessages]);

  useEffect(() => {
    !categoryAssistantTyping && inputRef.current?.focus();
  }, [categoryAssistantTyping, inputRef]);

  function addMessage(message) {
    categoryAssistantMessagesHandler(message);
  }

  async function userMessageHandler(content) {
    let message = { content: content, role: "user" };
    addMessage(message);
    setInput("");
    await assistantMessageHandler(content);
  }

  async function assistantMessageHandler(_userMessage, _firstMessage = false) {
    categoryAssistantTypingHandler(true);
    if (_firstMessage) {
      SetIsAssistantLoading(true);
    }

    let message = { content: "", role: "assistant" };

    if (_userMessage.toLowerCase() === "generate feedback") {
      _userMessage = _userMessage.toUpperCase();
    }

    try {
      let data = {};
      if (_firstMessage) {
        data = await HttpPostRequest(
          {
            user_message: "",
            user_id: userId,
            thread_id: categoryAssistantThreadId,
            reviewee_name: revieweeName,
            reviewee_job_title: revieweeJobTitle,
            review_category: categoryDetails.description,
            review_category_rating_scale: JSON.stringify(
              categoryDetails.rating_scale
            ),
          },
          "review_category_assistant"
        );
      } else {
        data = await HttpPostRequest(
          {
            user_message: _userMessage,
            user_id: userId,
            thread_id: categoryAssistantThreadId,
          },
          "review_category_assistant"
        );
      }

      if (data.status === "success") {
        if (data.data.response === "") {
          ToastNotification("Something went wrong. Please try again.");
          message.content = "Something went wrong. Please try again.";
        } else {
          message.content = data.data.response;

          if ("review" in data.data) {
            if (data.data.review.length > 0) {
              if (categoryReview !== data.data.review) {
                categoryReviewHandler(data.data.review);
                AddReviewMessageHandler(
                  `${categoryDetails.name} Review:\n\n${data.data.review}`
                );
                ToastNotification("Feedback successfully generated.");
                aiCompanionStatusHandler(AiCompanionStatuses.Scoring);

                let score = await GetCategoryReviewScore(
                  data.data.review,
                  categoryDetails
                );

                if (score !== null) {
                  categoryReviewScoreHandler(score);
                  aiCompanionMessagesHandler({
                    role: "assistant",
                    content: `${categoryDetails.name} Score: ${score}`,
                  });
                } else {
                  aiCompanionMessagesHandler({
                    role: "assistant",
                    content: `Unable to score review.`,
                  });
                }
              }
            }
          }
        }
      } else {
        console.log("API error with feedback assistant: ", data);
        if (data.message.includes("Oops!")) {
          message.content = data.message;
          ToastNotification(data.message);
        } else {
          message.content = "Something went wrong. Please try again.";
          ToastNotification(
            "Something went wrong with the feedback assistant. Please try again later."
          );
        }
      }
    } catch (error) {
      console.log("Error feedback assistant: ", error);
      message.content = "Something went wrong. Please try again.";
      ToastNotification(
        "Something went wrong with the feedback assistant. Please try again later."
      );
    }

    addMessage(message);
    categoryAssistantTypingHandler(false);
    if (_firstMessage) {
      SetIsAssistantLoading(false);
    }
    aiCompanionStatusHandler(AiCompanionStatuses.Idle);
  }

  return (
    <div className="flex flex-col space-y-2 w-full h-[90%] bg-gray1 rounded-b-lg p-2">
      <div className="flex flex-col h-[92%] w-full p-2 bg-white rounded-lg">
        <div
          ref={messagesEndRef}
          className="flex w-full h-[90%] px-1 overflow-y-auto relative"
        >
          {isAssistantLoading ? (
            <div className="absolute h-full w-[90%]">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="flex w-full h-full flex-col space-y-3">
              {categoryAssistantMessages.map((message, index) => {
                return (
                  <Message
                    key={index}
                    content={message.content}
                    role={message.role}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div className="h-[5%] md:h-[3%]">
          <p className="font-poppins text-[12px] text-center ">
            {categoryAssistantTyping && "Assistant is typing..."}
          </p>
        </div>
        <div className="flex w-full h-[10%] flex-row border-[2px] border-gray2 items-center px-1 justify-between">
          <textarea
            ref={inputRef}
            autoFocus
            disabled={categoryAssistantTyping}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && input.length > 0) {
                e.preventDefault();
                userMessageHandler(input);
              }
            }}
            placeholder="Type a message..."
            className="flex w-full h-[55%] font-poppins text-black bg-white border-0 focus:outline-none resize-none selection:bg-royal overflow-auto"
          ></textarea>
          <button
            disabled={input.length === 0}
            onClick={() => userMessageHandler(input)}
            className="flex items-center justify-center"
          >
            <MdSend className="h-[25px] w-[25px] text-royal hover:text-deeproyal" />
          </button>
        </div>
      </div>
      <div className="px-2 flex flex-row bg-gray1 rounded-b-lg h-[6%] justify-between">
        <FooterActionButton
          text="Reset"
          tooltip="Click to reset the conversation."
          disabled={categoryAssistantMessages.length === 0}
          action={() => categoryAssistantReset()}
          type="reset"
        />

        <FooterActionButton
          text="Generate"
          tooltip={
            categoryAssistantTyping
              ? "Conversate with the assistant to generate review."
              : "Generate review from the chat conversation."
          }
          disabled={categoryAssistantTyping}
          action={() => {
            categoryAssistantMessagesHandler({
              content: "GENERATE REVIEW",
              role: "system",
            });
            aiCompanionMessagesHandler({
              role: "user",
              content: `Please generate the review.`,
            });
          }}
          type="submit"
        />
      </div>
    </div>
  );
}

export default ChatWidget;

Message.propTypes = {
  content: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

function Message({ content, role }) {
  if (role === "assistant") {
    return (
      <div className="flex flex-row w-[60%] px-2 bg-gray1 rounded-tl-lg rounded-tr-lg rounded-br-lg py-2">
        <p className="w-full text-left text-[16px] font-poppins text-black">
          {content}
        </p>
      </div>
    );
  } else if (role === "user") {
    return (
      <div className="flex w-full justify-end">
        <div className="flex flex-row w-[60%] px-2 bg-royal rounded-tl-lg rounded-tr-lg rounded-bl-lg py-2 justify-end">
          <p className="w-full text-right text-[16px] font-poppins text-white">
            {content}
          </p>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
