import React from "react";
import PropTypes from "prop-types";

function EmailAddress({ emailTo, emailSubject }) {
  return (
    <span
      className="underline cursor-pointer text-deeproyal"
      onClick={() => window.open(`mailto:${emailTo}?subject=${emailSubject}`)}
    >
      {emailTo}
    </span>
  );
}

EmailAddress.propTypes = {
  emailTo: PropTypes.string.isRequired,
  emailSubject: PropTypes.string.isRequired,
};

export default EmailAddress;
