import { apiBaseUrl, userId } from "../context/BotContext";

export async function HttpPostRequest(data, api) {
  data["user_id"] = userId;
  let body = JSON.stringify(data);

  console.log("Request Body: ", body);

  let url = `${apiBaseUrl}/${api}`;
  console.log(`Sending request to: ${url}`);
  const res = await fetch(url, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: body,
  });

  try {
    const result = await res.json();

    console.log("Response: ", result);
    return result;
  } catch (error) {
    console.log("Error getting data: ", error);
  }
}
