import logo from "../../../img/Quadrillion_footer_logo.svg";
import * as Tooltip from "@radix-ui/react-tooltip";
import PrivacyPopup from "../../ui/PrivacyPopup";
import React from "react";
import { homePageUrl } from "../../../context/BotContext";

function FooterLayout() {
  return (
    <footer className="bg-gray2 w-full py-3 flex flex-col justify-evenly border-t-[2px] align-middle items-center md:flex-row">
      <div className="container flex flex-col space-y-3 w-full h-full md:w-[25%]">
        <p className="text-gray5 font-poppins text-center text-[12px]">
          We use Microsoft Clarity to collect data about how you use our
          website. This data helps us improve our products and advertising.
          Learn more in our{" "}
          <PrivacyPopup>
            <button className="underline">privacy statement</button>
          </PrivacyPopup>
          .
        </p>
        <Tooltip.Provider>
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild>
              <p className="text-gray5 font-poppins text-center text-[12px]">
                &copy; 2023 Quadrillion IO. All Rights Reserved
              </p>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                side="bottom"
                className=" text-gray5 font-poppins select-none text-[11px]"
              >
                v0.0.023
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
      <a href={homePageUrl} className="py-1 h-full w-[35%] md:w-[10%]">
        <img src={logo} alt="Logo" className="object-contain h-full w-full" />
      </a>
    </footer>
  );
}

export default FooterLayout;
