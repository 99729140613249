import { useContext } from "react";
import BotContext from "../../context/BotContext";
import { HttpPostRequest } from "../../helpers/HttpPostRequest";
import { IdentifySkills, IndetifyBiases } from "../../helpers/AnalyticsHelpers";
import ButtonTooltip from "../ui/ButtonTooltip";
import { ToastNotification } from "../ui/ToastNotifications";
import React from "react";

function InputFooter() {
  const {
    tone,
    style,
    chatMessages,
    chatReset,
    outputLoadingHandler,
    optimizedTextHandler,
    optimizedText,
    highlightNone,
    highlightBiases,
    highlightSkills,
    skillsToHighlightHandler,
    biasesToHighlightHandler,
    originalTextHandler,
    originalText,
    setOutputContextShowBiases,
    setOutputContextShowSkills,
    resetOutputContextAnalytics,
    notesList,
    notesListHandler,
    notesEditing,
    inputMode,
    isAssistantTyping,
    setChatMessages,
    setOutputView,
  } = useContext(BotContext);

  function clearButtonHandler() {
    if (inputMode === "Notes") {
      notesListHandler([]);
    } else if (inputMode === "Chat") {
      chatReset();
    } else {
      originalTextHandler("");
    }
  }

  function clearButtonDisabled() {
    if (inputMode === "Notes") {
      return notesList.length === 0;
    } else if (inputMode === "Chat" && !isAssistantTyping) {
      return chatMessages.length === 0;
    } else {
      return originalText === "";
    }
  }

  function clearButtonTooltip() {
    if (inputMode === "Notes") {
      return "Click to remove all notes.";
    } else if (inputMode === "Chat") {
      return "Click to reset the conversation.";
    } else {
      return "Click to clear the text in this field.";
    }
  }

  function submitButtonDisabled() {
    if (inputMode === "Notes") {
      if (notesList.length === 0) {
        return true;
      } else {
        if (notesEditing > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (inputMode === "Chat") {
      return isAssistantTyping;
    } else {
      if (originalText.length === 0) {
        return true;
      } else {
        if (
          tone.toLowerCase() === "none" &&
          style.toLowerCase() === "none" &&
          highlightNone
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  function submitButtonTooltip() {
    if (inputMode === "Notes") {
      if (submitButtonDisabled()) {
        return "Save all edits to the notes to generate feedback for the selected style and tone.";
      } else {
        return "Generate feedback from the notes for the selected style and tone.";
      }
    } else if (inputMode === "Chat") {
      if (submitButtonDisabled()) {
        return "Conversate with the assistant to generate feedback.";
      } else {
        return "Generate feedback from the chat conversation.";
      }
    } else {
      if (
        tone.toLowerCase() === "none" &&
        style.toLowerCase() === "none" &&
        highlightNone
      ) {
        return "Select a tone, style, or highlight.";
      } else {
        if (
          tone.toLowerCase() === "none" &&
          style.toLowerCase() === "none" &&
          !highlightNone
        ) {
          if (submitButtonDisabled()) {
            return "Write text to recognize biases and/or skills.";
          } else {
            return "Highlight the recognized biases and/or skills within the text.";
          }
        } else {
          if (submitButtonDisabled()) {
            return "Write text to generate feedback for the selected style and tone.";
          } else {
            return "Rephrase text to match the selected style and tone.";
          }
        }
      }
    }
  }

  function submitButtonName() {
    if (inputMode === "Notes" || inputMode === "Chat") {
      return "Generate";
    } else {
      if (
        tone.toLowerCase() === "none" &&
        style.toLowerCase() === "none" &&
        !highlightNone
      ) {
        return "Submit";
      } else {
        return "Rephrase";
      }
    }
  }

  const onSubmit = async () => {
    try {
      optimizedTextHandler("");
      resetOutputContextAnalytics();
      setOutputView("Feedback");

      let input_text = "";
      let output_text = "";

      if (inputMode === "Notes") {
        let strengths = "";
        let challenges = "";
        let overview = "";

        notesList.forEach((note) => {
          if (note.type === "Strength") {
            strengths += "- " + note.note + "\n";
          } else if (note.type === "Challenge") {
            challenges += "- " + note.note + "\n";
          } else if (note.type === "Overview") {
            overview += "- " + note.note + "\n";
          }
        });

        if (strengths.length > 0) {
          input_text += "Strengths:\n";
          input_text += strengths;
        }
        if (challenges.length > 0) {
          input_text += "Challenges:\n";
          input_text += challenges;
        }
        if (overview.length > 0) {
          input_text += "Overview:\n";
          input_text += overview;
        }
        outputLoadingHandler("Generating...", true);

        const data = await HttpPostRequest(
          {
            input_text: input_text,
            style: style,
            sentiment: tone,
          },
          "generate_text"
        );

        if (data.status === "success") {
          output_text = data.data.output_text;
        } else {
          console.log("API error generating text: ", data);
          ToastNotification(
            "Something went wrong while generating the text. Please try again."
          );
        }
      } else if (inputMode === "Chat") {
        setChatMessages([
          ...chatMessages,
          { content: "GENERATE FEEDBACK", role: "system" },
        ]);
      } else {
        input_text = originalText;
        outputLoadingHandler("Rephrasing...", true);

        if (tone.toLowerCase() === "none" && style.toLowerCase() === "none") {
          output_text = input_text;
        } else {
          const data = await HttpPostRequest(
            {
              input_text: input_text,
              style: style,
              sentiment: tone,
            },
            "optimize_text"
          );

          if (data.status === "success") {
            output_text = data.data.output_text;
          } else {
            console.log("API error rephrasing text: ", data);
            ToastNotification(
              "Something went wrong while rephrasing. Please try again."
            );
          }
        }
      }

      if (output_text.length !== 0) {
        if (output_text === optimizedText) {
          ToastNotification("No changes were made to the text.");
        }
        optimizedTextHandler(output_text);

        if (!highlightNone) {
          try {
            outputLoadingHandler("Analyzing...", true);

            if (highlightBiases && highlightSkills) {
              const [skills, biases] = await Promise.all([
                IdentifySkills(output_text),
                IndetifyBiases(output_text),
              ]);

              if (skills.length > 0) {
                skillsToHighlightHandler(skills);
              }

              if (biases.length > 0) {
                biasesToHighlightHandler(biases);
              }
              setOutputContextShowBiases(true);
              setOutputContextShowSkills(true);
            } else if (highlightBiases) {
              const biases = await IndetifyBiases(output_text);
              if (biases.length > 0) {
                biasesToHighlightHandler(biases);
              }
              setOutputContextShowBiases(true);
            } else if (highlightSkills) {
              const skills = await IdentifySkills(output_text);
              if (skills.length > 0) {
                skillsToHighlightHandler(skills);
              }
              setOutputContextShowSkills(true);
            }
          } catch (error) {
            console.log("Error analyzing text: ", error);
            ToastNotification(
              "Something went wrong while gathering analytics. Please try again."
            );
          }
        }
      }
    } catch (error) {
      console.log("Error optimizng text: ", error);
      ToastNotification("Something went wrong. Please try again.");
    }

    outputLoadingHandler("", false);
  };

  return (
    <div className="px-2 md:pl-8 md:pr-2 pb-6 flex bg-gray1 rounded-b-lg h-[10%]">
      <div
        className={`${
          inputMode !== "Notes" && "bg-white"
        } flex flex-row justify-between px-2 w-full`}
      >
        <ButtonTooltip text={clearButtonTooltip()}>
          <button
            disabled={clearButtonDisabled()}
            onClick={() => clearButtonHandler()}
            type="reset"
            className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal float-right"
          >
            {inputMode === "Chat" ? "Reset" : "Clear"}
          </button>
        </ButtonTooltip>

        <ButtonTooltip text={submitButtonTooltip()}>
          <button
            disabled={submitButtonDisabled()}
            onClick={onSubmit}
            type="submit"
            className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal float-right"
          >
            {submitButtonName()}
          </button>
        </ButtonTooltip>
      </div>
    </div>
  );
}

export default InputFooter;
