import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import HomePage from "./pages/HomePage";
import PerformanceReviewPage from "./pages/PerformanceReviewPage";
import React from "react";
import { BotContextProvider } from "../src/context/BotContext";
import { ReviewContextProvider } from "../src/context/ReviewContext";

function App() {
  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <BotContextProvider>
              <HomePage />
            </BotContextProvider>
          }
        />
        <Route
          path="/performance-review"
          element={
            <BotContextProvider>
              <ReviewContextProvider>
                <PerformanceReviewPage />
              </ReviewContextProvider>
            </BotContextProvider>
          }
        />
      </Routes>
    </Layout>
  );
}

export default App;
