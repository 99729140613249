import React from "react";
import PropTypes from "prop-types";
import { MdModeEditOutline } from "react-icons/md";
import ButtonTooltip from "./ButtonTooltip";
import ReviewContext from "../../context/ReviewContext";
import { InputModes } from "../../constants/Constants";
import { useContext } from "react";

function ChatMessage({ content, role, type = "default" }) {
  const { expandCopilotHandler, inputMode, categoryReview, inputModeHandler } =
    useContext(ReviewContext);

  if (role === "assistant") {
    return (
      <div className="flex max-w-[95%] p-2 bg-gray1 rounded-tl-lg rounded-tr-lg rounded-br-lg text-left text-[16px] font-poppins text-black whitespace-pre-line relative">
        <span className="z-0">{content}</span>

        {type === "review" && (
          <ButtonTooltip text={"Click to refine the review."}>
            <button
              disabled={
                !content.includes(categoryReview) ||
                inputMode === InputModes.Refine
              }
              onClick={() => {
                expandCopilotHandler(false);
                inputModeHandler(InputModes.Refine);
              }}
              className="absolute text-royal hover:text-deeproyal text-[20px] top-[8px] right-[8px] z-10"
            >
              <MdModeEditOutline
                className={`${
                  !content.includes(categoryReview) ||
                  inputMode === InputModes.Refine
                    ? "text-gray4"
                    : ""
                } `}
              />
            </button>
          </ButtonTooltip>
        )}
      </div>
    );
  } else if (role === "user") {
    return (
      <div className="flex w-full justify-end">
        <div className="flex flex-row max-w-[95%] p-2 bg-royal rounded-tl-lg rounded-tr-lg rounded-bl-lg justify-end text-[16px] font-poppins text-white text-right">
          {content}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

ChatMessage.propTypes = {
  content: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default ChatMessage;
