import logo from "../../../img/Quadrillion_logo.svg";
import FeedbackPopup from "../../ui/FeedbackPopup";
import ButtonTooltip from "../../ui/ButtonTooltip";
import { MdOutlineHelpOutline, MdOutlineViewCarousel } from "react-icons/md";
import { homePageUrl, userGuideUrl } from "../../../context/BotContext";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

function HeaderLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className="flex flex-row pt-3 bg-gray2 items-center align-middle mx-auto md:justify-between md:space-x-5 md:w-full md:h-[10%] md:px-6">
      <a className="invisible md:visible" href={homePageUrl}>
        <img
          src={logo}
          alt="Logo"
          className="invisible md:visible object-scale-down md:w-72"
        />
      </a>
      <div className="flex flex-row space-x-2 align-middle">
        <FeedbackPopup />

        <div className="flex md:items-end md:align-bottom">
          <ButtonTooltip text="Click to open the user guide.">
            <a
              href={userGuideUrl}
              target="_blank"
              rel="noreferrer"
              className="flex flex-row items-center whitespace-nowrap p-[7px] text-royal hover:text-deeproyal bg-white rounded-full baseline hover:border-deeproyal"
            >
              <MdOutlineHelpOutline size={32} />
            </a>
          </ButtonTooltip>
        </div>

        <div className="flex md:items-end md:align-bottom">
          <ButtonTooltip text="Click to switch mode.">
            <button
              onClick={() => {
                if (location.pathname === "/performance-review") {
                  navigate("/");
                } else {
                  navigate("/performance-review");
                }
              }}
              className="flex flex-row items-center whitespace-nowrap p-[7px] text-royal hover:text-deeproyal bg-white rounded-full baseline hover:border-deeproyal"
            >
              <MdOutlineViewCarousel size={32} />
            </button>
          </ButtonTooltip>
        </div>
      </div>
    </nav>
  );
}

export default HeaderLayout;
