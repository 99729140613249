import React from "react";
import { useContext } from "react";
import ReviewContext from "../../../context/ReviewContext";

function ReviewInformation() {
  const { reviewCategories } = useContext(ReviewContext);

  return (
    <div className="font-poppins bg-gray1 flex flex-col w-full h-full rounded-lg p-3">
      <div className="flex flex-col space-y-3 h-full w-full flex-1 overflow-y-auto bg-white rounded-lg p-2 font-poppins">
        {Object.keys(reviewCategories).map((categoryName) => {
          return (
            <div key={categoryName} className="flex flex-col space-y-1">
              <span className="text-xl font-bold">
                {categoryName}{" "}
                <span className="text-base">
                  (Score: {reviewCategories[categoryName].categoryReviewScore})
                </span>
              </span>
              <span>{reviewCategories[categoryName].categoryReview}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ReviewInformation;
