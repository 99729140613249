import { useContext } from "react";
import BotContext from "../../context/BotContext";
import ButtonTooltip from "../ui/ButtonTooltip";
import React from "react";
import { toJpeg } from "html-to-image";
import { ToastNotification } from "../ui/ToastNotifications";

function OutputFooter() {
  const {
    optimizedText,
    outputView,
    insightsView,
    outputLoadingText,
    isOutputLoading,
    resetOutputContextAnalytics,
    optimizedTextHandler,
    originalTextHandler,
    outputContextShowBiases,
    outputContextShowSkills,
    notesListHandler,
    setChatMessages,
    inputMode,
    setInputMode,
    strengths,
    weaknesses,
    neutrals,
  } = useContext(BotContext);

  function copyToClipboard() {
    try {
      navigator.clipboard.writeText(optimizedText);
    } catch {
      ToastNotification(
        "Something went wrong. Please copy the feedback manually."
      );
    }
  }

  function OnCopySkills() {
    const capitalizeFirst = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    let content = "";

    if (strengths.length > 0) {
      content += "Strengths:\n";
      strengths.forEach((skill) => {
        content += "- " + capitalizeFirst(skill.skill) + "\n";
      });
    }
    if (weaknesses.length > 0) {
      content.length > 0 && (content += "\n");
      content += "Weaknesses:\n";
      weaknesses.forEach((skill) => {
        content += "- " + capitalizeFirst(skill.skill) + "\n";
      });
    }
    if (neutrals.length > 0) {
      content.length > 0 && (content += "\n");
      content += "Neutrals:\n";
      neutrals.forEach((skill) => {
        content += "- " + capitalizeFirst(skill.skill) + "\n";
      });
    }

    navigator.clipboard.writeText(content);
  }

  function renderElement() {
    if (outputView === "Feedback") {
      return (
        <div className="bg-white flex justify-between px-2 relative h-full">
          <ButtonTooltip text="Click to shift the text to the left for further rephrasing.">
            <button
              disabled={optimizedText === ""}
              onClick={() => {
                if (inputMode === "Notes") {
                  notesListHandler([]);
                  setInputMode("Free-Hand");
                }

                if (inputMode === "Chat") {
                  setChatMessages([]);
                  setInputMode("Free-Hand");
                }
                originalTextHandler(optimizedText);
                document.getElementById("input-textarea")?.focus();
                optimizedTextHandler("");
                resetOutputContextAnalytics();
              }}
              type="submit"
              className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
            >
              Shift
            </button>
          </ButtonTooltip>
          <div className="flex flex-row-reverse space-x-reverse space-x-2">
            <ButtonTooltip text="Click to launch your email client with the text pre-filled in the body.">
              <button
                disabled={optimizedText === ""}
                onClick={() => window.open(`mailto:?body=${optimizedText}`)}
                type="submit"
                className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
              >
                Email
              </button>
            </ButtonTooltip>
            <ButtonTooltip text="Click to copy the text to your clipboard.">
              <button
                disabled={optimizedText === ""}
                onClick={copyToClipboard}
                type="submit"
                className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
              >
                Copy
              </button>
            </ButtonTooltip>
          </div>
        </div>
      );
    } else if (outputView === "Insights") {
      if (insightsView === "Evaluation") {
        return (
          <div className="bg-white flex justify-end px-2 relative h-full">
            <ButtonTooltip text="Click to download feedback evaluation.">
              <button
                onClick={() =>
                  toJpeg(document.getElementById("NineBoxEvaluationChart"), {
                    quality: 0.95,
                  }).then(function (dataUrl) {
                    var link = document.createElement("a");
                    link.download = "NineBoxEvaluationChart.jpeg";
                    link.href = dataUrl;
                    link.click();
                  })
                }
                type="submit"
                className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
              >
                Download
              </button>
            </ButtonTooltip>
          </div>
        );
      }
      if (insightsView === "Skills") {
        return (
          <div className="bg-white flex justify-end px-2 relative h-full">
            <ButtonTooltip text="Click to copy the text to your clipboard.">
              <button
                onClick={OnCopySkills}
                type="submit"
                className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
              >
                Copy Skills
              </button>
            </ButtonTooltip>
          </div>
        );
      }
    }
  }

  return (
    <div className="px-2 md:pr-8 md:pl-2 pb-6 bg-gray1 rounded-b-lg h-[10%] w-full relative">
      {renderElement()}

      <div className="h-7 w-full items-center justify-center absolute">
        {isOutputLoading && (
          <p className="font-poppins text-center text-[14px] text-gray5">
            {outputLoadingText}
          </p>
        )}
        {outputView === "Feedback" && !isOutputLoading && (
          <div className="flex space-x-5 items-center justify-center">
            {outputContextShowBiases && (
              <div className="flex space-x-2 items-center ">
                <div className="w-3 h-3 bg-darktangerine rounded-full"></div>
                <p className="text-dark5 font-poppins text-[14px]">Bias</p>
              </div>
            )}

            {outputContextShowSkills && (
              <div className="flex space-x-2 items-center ">
                <div className="w-3 h-3 bg-magicmint rounded-full"></div>
                <p className="text-dark5 font-poppins text-[14px]">Skills</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default OutputFooter;
