import FooterLayout from "./footer/FooterLayout";
import HeaderLayout from "./header/HeaderLayout";
import { Toaster } from "react-hot-toast";
import React from "react";
import PropTypes from "prop-types";

function Layout(props) {
  return (
    <div className="flex flex-col space-y-2 w-full min-h-screen max-h-screen bg-gray2">
      <Toaster />

      <HeaderLayout />
      {props.children}
      <FooterLayout />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
