import { HttpPostRequest } from "./HttpPostRequest";
import { ToastNotification } from "../components/ui/ToastNotifications";

const biasTopics = [
  "age-discrimination",
  "disability-discrimination-and-employment-decisions",
  "equal-paycompensation-discrimination",
  "genetic-information-discrimination",
  "national-origin-discrimination",
  "pregnancy-discrimination",
  "racecolor-discrimination",
  "religious-discrimination",
  "sex-based-discrimination",
  "sexual-orientation-and-gender-identity-sogi-discrimination",
];

export async function IdentifySkills(text) {
  try {
    const data = await HttpPostRequest(
      {
        input_text: text,
      },
      "identify_skills"
    );

    if (data.status === "success") {
      return data.data.skills;
    } else {
      console.log("API error identifying skills: ", data);
      ToastNotification(
        "Something went wrong while identifying skills. Please try again."
      );
    }
  } catch (error) {
    console.log("Error identifying skills: ", error);
    ToastNotification(
      "Something went wrong while identifying skills. Please try again."
    );
  }
  return [];
}

export async function IndetifyBiases(text) {
  try {
    let showWarning = false;
    const data = await Promise.all(
      biasTopics.map(async (topic) => {
        try {
          const data = await HttpPostRequest(
            {
              input_text: text,
              topic: topic,
            },
            "identify_biases"
          );
          if (data.status === "success") {
            return data.data.phrases;
          } else {
            console.log(`API error identifying ${topic} biases: ${data}`);
            showWarning = true;
          }
        } catch (error) {
          console.log(`API error identifying ${topic} biases: ${error}`);
          showWarning = true;
        }
        return [];
      })
    );

    if (showWarning) {
      ToastNotification(
        "Warning: Some bias-check evaluations were not successfully fulfilled."
      );
    }

    let identifiedBiases = [];
    data.forEach((list) => {
      if (list && list.length > 0) {
        identifiedBiases = identifiedBiases.concat(list);
      }
    });
    identifiedBiases = [...new Set(identifiedBiases)];

    return identifiedBiases;
  } catch (error) {
    console.log(`API error identifying biases: ${error}`);
    ToastNotification(
      `Something went wrong while identifying biases. Please try again.`
    );
  }
  return [];
}

export async function NineBoxEvaluation(text) {
  try {
    const data = await HttpPostRequest(
      {
        input_text: text,
      },
      "nine_box_evaluation"
    );

    if (data.status === "success") {
      return data.data.nine_box_evaluation;
    } else {
      console.log("API error getting nine box evaluation: ", data);
      if (data.message.includes("a little sleepy")) {
        ToastNotification(data.message);
      } else {
        ToastNotification(
          "Something went wrong while getting nine box evaluation. Please try again."
        );
      }
    }
  } catch (error) {
    console.log("Error with nine box evaluation: ", error);
    ToastNotification(
      "Something went wrong while getting nine box evaluation. Please try again."
    );
  }
  return {
    poor_performer_score: 0,
    average_performer_score: 0,
    solid_performer_score: 0,
    inconsistent_performer_score: 0,
    core_player_score: 0,
    high_performer_score: 0,
    potential_gem_score: 0,
    great_potential_score: 0,
    star_performer_score: 0,
  };
}
