import OutputHeader from "./OutputHeader";
import OutputFooter from "./OutputFooter";
import OutputTextArea from "./OutputTextArea";
import SkillsAnalytics from "./SkillsAnalytics";
import NineBoxEvaluationChart from "./NineBoxEvaluationChart";
import React from "react";
import PropTypes from "prop-types";

function OutputTabLayout({ outputView, insightsView }) {
  return (
    <div className="flex flex-col w-full h-full">
      <OutputHeader />
      <div className="px-2 md:pr-8 md:pl-2 pt-2 bg-gray1 selection:bg-royal selection:bg-opacity-30 selection:text-transparent">
        <div className="block relative overflow-hidden w-full h-[420px] md:h-[525px] text-[22px] font-poppins bg-white border-0">
          {outputView === "Feedback" && <OutputTextArea />}
          {outputView === "Insights" && insightsView === "Evaluation" && (
            <NineBoxEvaluationChart />
          )}
          {outputView === "Insights" && insightsView === "Skills" && (
            <SkillsAnalytics />
          )}
        </div>
      </div>
      <OutputFooter />
    </div>
  );
}

OutputTabLayout.propTypes = {
  outputView: PropTypes.string.isRequired,
  insightsView: PropTypes.string,
};

export default OutputTabLayout;
