import { useState } from "react";

export default function useToggle(defaultvalue) {
  const [value, setValue] = useState(defaultvalue);

  function toggleValue(value) {
    setValue((currentValue) =>
      typeof value === "boolean" ? value : !currentValue
    );
  }
  return [value, toggleValue];
}
