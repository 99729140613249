import { useState, useEffect, useContext } from "react";
import ButtonTooltip from "../../ui/ButtonTooltip";
import React from "react";
import PropTypes from "prop-types";
import * as Collapsible from "@radix-ui/react-collapsible";
import { MdArrowDropDown, MdArrowDropUp, MdDone } from "react-icons/md";
import ReviewContext from "../../../context/ReviewContext";
import { AiCompanionStatuses } from "../../../constants/Constants";
import FooterActionButton from "../../ui/FooterActionButton";
import { ToastNotification } from "../../ui/ToastNotifications";
import { HttpPostRequest } from "../../../helpers/HttpPostRequest";
import { GetCategoryReviewScore } from "../../../helpers/GetCategoryReviewScore";

function InputNotes({ categoryDetails }) {
  const {
    AddReviewMessageHandler,
    categoryAnswers,
    categoryReviewScoreHandler,
    revieweeJobTitle,
    categoryReviewHandler,
    aiCompanionMessagesHandler,
    aiCompanionStatusHandler,
  } = useContext(ReviewContext);

  function submitButtonDisabled() {
    for (let i = 0; i < categoryAnswers.length - 1; i++) {
      if (categoryAnswers[i].answer === "") {
        return true;
      }
    }
    return false;
  }

  async function onSubmit() {
    try {
      let output_text = "";

      aiCompanionStatusHandler(AiCompanionStatuses.Generating);

      aiCompanionMessagesHandler({
        role: "user",
        content: `Please generate the review.`,
      });

      const data = await HttpPostRequest(
        {
          reviewer_answers: JSON.stringify(categoryAnswers),
          reviewee_job_title: revieweeJobTitle,
          category_name: categoryDetails.name,
          category_description: categoryDetails.description,
        },
        "generate_category_review"
      );

      if (data.status === "success") {
        output_text = data.data.output_text;
      } else {
        console.log("API error generating text: ", data);
        ToastNotification(
          "Something went wrong while generating the text. Please try again."
        );
      }

      if (output_text.length !== 0) {
        AddReviewMessageHandler(
          `${categoryDetails.name} Review:\n\n${output_text}`
        );
        categoryReviewHandler(output_text);

        aiCompanionStatusHandler(AiCompanionStatuses.Scoring);

        let score = await GetCategoryReviewScore(output_text, categoryDetails);

        if (score !== null) {
          categoryReviewScoreHandler(score);
          aiCompanionMessagesHandler({
            role: "assistant",
            content: `${categoryDetails.name} Score: ${score}`,
          });
        } else {
          aiCompanionMessagesHandler({
            role: "assistant",
            content: `Unable to score review.`,
          });
        }
      }
    } catch (error) {
      console.log("Error optimizng text: ", error);
      ToastNotification("Something went wrong. Please try again.");
    }

    aiCompanionStatusHandler(AiCompanionStatuses.Idle);
  }

  return (
    <div className="flex flex-col space-y-2 w-full h-[90%] bg-gray1 rounded-b-lg p-2">
      <div className="flex-col space-y-4 w-full h-[93%] overflow-y-auto">
        {categoryDetails.personalizedDetails.questions.map(
          (question, index) => (
            <CollapsibleSection key={index} index={index} question={question} />
          )
        )}
      </div>
      <div className="px-2 flex flex-row bg-gray1 rounded-b-lg h-[6%] justify-end">
        <FooterActionButton
          text="Generate"
          tooltip={
            submitButtonDisabled()
              ? "Save all answers to generate the review."
              : "Generate the from the answers."
          }
          disabled={submitButtonDisabled()}
          action={onSubmit}
          type="submit"
        />
      </div>
    </div>
  );
}

InputNotes.propTypes = {
  categoryDetails: PropTypes.object.isRequired,
};

function CollapsibleSection({ question, index }) {
  const {
    categoryAnswers,
    categoryAnswersHandler,
    categoryCurrentQuestionIndex,
  } = useContext(ReviewContext);
  const [open, setOpen] = useState(false);
  const [answerText, setAnswerText] = useState("");

  useEffect(() => {
    if (categoryCurrentQuestionIndex === index) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [categoryCurrentQuestionIndex]);

  return (
    <Collapsible.Root
      className="flex w-full flex-col space-y-2 bg-white rounded-lg font-poppins text-black"
      open={open}
      onOpenChange={setOpen}
    >
      <Collapsible.Trigger asChild>
        <div className="flex items-center justify-between cursor-pointer">
          <span className="text-[23px] px-2 font-medium">
            {question.question}
          </span>
          <div className="flex-row space-x-1 inline-flex items-center justify-center">
            {categoryAnswers[index].answer !== null && (
              <MdDone className="text-[24px] text-green-800" />
            )}
            <button className="text-[32px] text-royal hover:text-deeproyal">
              {open ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
          </div>
        </div>
      </Collapsible.Trigger>

      <Collapsible.Content>
        <div className="flex flex-col h-full w-full ">
          <textarea
            autoFocus
            id="editor"
            className="block w-full px-2 text-[22px] font-poppins text-gray5 bg-white border-0 focus:outline-none resize-none selection:bg-royal
selection:bg-opacity-30 rounded-lg"
            rows={2}
            value={answerText}
            onChange={(e) => {
              setAnswerText(e.target.value);
            }}
            placeholder={"Enter text"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (answerText.length !== 0) {
                  e.preventDefault();
                  categoryAnswersHandler(
                    index,
                    question.question,
                    answerText,
                    false
                  );
                  setOpen(false);
                }
              }
            }}
          ></textarea>

          <div className="bg-white h-full w-full p-2 rounded-b-lg flex flex-row justify-between">
            {question.answers.length === 0 ? (
              <div></div>
            ) : (
              <div className="grid grid-flow-row grid-rows-1 grid-cols-3 gap-2 w-[75%]">
                {question.answers.map((answer, index) => (
                  <SampleAnswerButton
                    key={index}
                    text={answer}
                    onClick={setAnswerText}
                  />
                ))}
              </div>
            )}
            <ButtonTooltip
              text={
                categoryAnswers[index].answer !== null
                  ? "Click to update your feedback."
                  : "Click to save your feedback."
              }
            >
              <button
                disabled={answerText.length === 0}
                onClick={() => {
                  categoryAnswersHandler(
                    index,
                    question.question,
                    answerText,
                    false
                  );
                  setOpen(false);
                }}
                className="h-full text-[15px] px-4 py-[4px] text-center flex items-center text-white font-poppins bg-royal rounded-full hover:bg-deeproyal"
              >
                {categoryAnswers[index].answer !== null ? "Update" : "Save"}
              </button>
            </ButtonTooltip>
          </div>
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
}

CollapsibleSection.propTypes = {
  question: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

function SampleAnswerButton({ text, onClick }) {
  return (
    <ButtonTooltip text={`Click to insert the following text: ${text}`}>
      <button
        onClick={() => {
          onClick(text);
        }}
        className="h-full text-[15px] px-4 py-[4px] w-full text-center flex items-center text-white font-poppins bg-royal rounded-full hover:bg-deeproyal"
      >
        <span className="whitespace-nowrap w-full text-ellipsis overflow-hidden">
          {text}
        </span>
      </button>
    </ButtonTooltip>
  );
}

SampleAnswerButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default InputNotes;
