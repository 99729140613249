import { HttpPostRequest } from "./HttpPostRequest";

export async function GetCategoryReviewScore(review, categoryDetails) {
  let score = null;

  try {
    const data = await HttpPostRequest(
      {
        review: review,
        category_name: categoryDetails.name,
        category_description: categoryDetails.description,
        rating_scale: JSON.stringify(categoryDetails.rating_scale),
      },
      "generate_review_score"
    );

    if (data.status === "success") {
      score = data.data.score;
    } else {
      console.log("Error getting review score.");
    }
  } catch {
    console.log("Something went wrong when getting category details");
  }

  return score;
}
