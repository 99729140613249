import ButtonTooltip from "../ui/ButtonTooltip";
import React from "react";
import PropTypes from "prop-types";

function ActionButton({ text, action, icon, disabled, tooltip }) {
  return (
    <ButtonTooltip text={tooltip}>
      <button
        disabled={disabled}
        onClick={action}
        className="whitespace-nowrap justify-center items-center flex flex-row space-x-1 text-[15px] px-1 md:px-3 py-[3px] bg-royal hover:bg-deeproyal rounded-lg text-white font-poppins"
      >
        <div className="container w-[16px] h-full flex justify-center items-center">
          {icon}
        </div>
        <p>{text}</p>
      </button>
    </ButtonTooltip>
  );
}

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  disabled: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default ActionButton;
