import InputNotes from "../input/InputNotes";
import InputChat from "../input/InputChat";
import React from "react";
import { useContext } from "react";
import HeaderDropdown from "../../ui/HeaderDropdown";
import PropTypes from "prop-types";
import ReviewContext from "../../../context/ReviewContext";
import { InputModes } from "../../../constants/Constants";
import Refine from "../input/Refine";

function InputLayout({ categoryDetails }) {
  const { inputMode, inputModeHandler } = useContext(ReviewContext);

  if (inputMode === InputModes.Refine) {
    return <Refine />;
  } else {
    return (
      <div className="flex flex-col w-full h-full bg-gray1 space-y-2 rounded-lg">
        <div className="flex flex-row items-center align-middle justify-between p-2 bg-gray1 h-[8%] rounded-t-lg">
          <HeaderDropdown
            title="Mode"
            tooltip="Choose the input mode for this category."
            initialValue={inputMode}
            onValueChange={inputModeHandler}
            radioItems={[InputModes.Notes, InputModes.AiInterview]}
          />
        </div>

        {inputMode === InputModes.Notes && (
          <InputNotes categoryDetails={categoryDetails} />
        )}
        {inputMode === InputModes.AiInterview && <InputChat />}
      </div>
    );
  }
}

InputLayout.propTypes = {
  categoryDetails: PropTypes.object.isRequired,
};

export default InputLayout;
