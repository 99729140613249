import StyleDropdown from "./StyleDropdown";
import { useContext } from "react";
import BotContext from "../../context/BotContext";
import HighlightDropdown from "./HighlightDropdown";
import ToneDropdown from "./ToneDropdown";
import React from "react";

function InputHeader() {
  const { tone, style, updateToneHandler, updateStyleHandler } =
    useContext(BotContext);

  return (
    <div className="flex flex-row items-center align-middle justify-center space-x-2 xl:space-x-4 2xl:space-x-10 px-2 md:pl-8 md:pr-2 bg-gray1 h-[10%]">
      <StyleDropdown style={style} updateStyle={updateStyleHandler} />

      <ToneDropdown tone={tone} updateTone={updateToneHandler} />

      <HighlightDropdown />
    </div>
  );
}

export default InputHeader;
