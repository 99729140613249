import { useContext, useEffect, useState } from "react";
import ReviewContext from "../../../context/ReviewContext";
import React from "react";
import HeaderDropdown from "../../ui/HeaderDropdown";
import ButtonTooltip from "../../ui/ButtonTooltip";
import { HttpPostRequest } from "../../../helpers/HttpPostRequest";
import { ToastNotification } from "../../ui/ToastNotifications";
import ActionButton from "../../ui/HeaderActionButton";
import {
  MdExpand,
  MdCompress,
  MdFindInPage,
  MdAutoFixHigh,
} from "react-icons/md";
import CustomActionButton from "./CustomActionButton";
import { AiCompanionStatuses } from "../../../constants/Constants";
import useToggle from "../../../hooks/useToggle";
import LoadingSpinner from "../../ui/LoadingSpinner";
import { GetCategoryReviewScore } from "../../../helpers/GetCategoryReviewScore";
import { IndetifyBiases } from "../../../helpers/AnalyticsHelpers";

function InputTextArea() {
  const {
    categoryDetails,
    AddReviewMessageHandler,
    categoryReview,
    aiCompanionMessagesHandler,
    categoryReviewHandler,
    categoryReviewScoreHandler,
    aiCompanionStatusHandler,
  } = useContext(ReviewContext);
  const [text, setText] = useState(categoryReview);
  const [tone, setTone] = useState("None");
  const [loading, setLoading] = useToggle(false);
  const [style, setStyle] = useState("None");
  const [biases, setBiases] = useState([]);

  useEffect(() => {
    if (biases.length > 0) {
      setBiases([]);
    }
  }, [text]);

  function submitButtonTooltip() {
    if (
      tone.toLowerCase() === "none" &&
      style.toLowerCase() === "none" &&
      text === categoryReview
    ) {
      return "Select a tone or style to continue or edit the text.";
    } else {
      if (tone.toLowerCase() !== "none" || style.toLowerCase() !== "none") {
        return "Rephrase text to match the selected style and tone.";
      } else {
        return "Save the changes.";
      }
    }
  }

  const onSubmit = async () => {
    try {
      let output_text = "";

      aiCompanionStatusHandler(AiCompanionStatuses.Refining);
      setLoading(true);

      aiCompanionMessagesHandler({
        role: "user",
        content: `Please refine the review.`,
      });

      if (tone.toLowerCase() === "none" && style.toLowerCase() === "none") {
        output_text = text;
      } else {
        const data = await HttpPostRequest(
          {
            input_text: text,
            style: style,
            sentiment: tone,
          },
          "optimize_text"
        );

        if (data.status === "success") {
          output_text = data.data.output_text;
        } else {
          console.log("API error rephrasing text: ", data);
          ToastNotification(
            "Something went wrong while rephrasing. Please try again."
          );
        }
      }

      if (output_text.length !== 0) {
        AddReviewMessageHandler(
          `${categoryDetails.name} Review:\n\n${output_text}`
        );
        categoryReviewHandler(output_text);
        setText(output_text);
        setTone("None");
        setStyle("None");

        aiCompanionStatusHandler(AiCompanionStatuses.Scoring);

        let score = await GetCategoryReviewScore(output_text, categoryDetails);

        if (score !== null) {
          categoryReviewScoreHandler(score);
          aiCompanionMessagesHandler({
            role: "assistant",
            content: `${categoryDetails.name} Score: ${score}`,
          });
        } else {
          aiCompanionMessagesHandler({
            role: "assistant",
            content: `Unable to score review.`,
          });
        }
      }
    } catch (error) {
      console.log("Error refining text: ", error);
      ToastNotification("Something went wrong. Please try again.");
    }
    aiCompanionStatusHandler(AiCompanionStatuses.Idle);
    setLoading(false);
  };

  const checkBiasesHandler = async () => {
    setLoading(true);
    aiCompanionMessagesHandler({
      role: "user",
      content: `Please check for biases.`,
    });
    let identifiedBiases = await IndetifyBiases(text);
    setBiases(identifiedBiases);
    console.log(identifiedBiases);
    if (identifiedBiases.length > 0) {
      aiCompanionMessagesHandler({
        role: "assistant",
        content: `The following biases were identified:${identifiedBiases.map(
          (bias) => {
            return `\n- ${bias}`;
          }
        )}`,
      });
    } else {
      aiCompanionMessagesHandler({
        role: "assistant",
        content: `No biases were identified.`,
      });
    }

    setLoading(false);
  };

  const fixBiasesHandler = async () => {
    setLoading(true);
    try {
      let output_text = "";

      const data = await HttpPostRequest(
        {
          input_text: text,
          biases: biases,
        },
        "bias_resolution"
      );

      if (data.status === "success") {
        output_text = data.data.output_text;
      } else {
        console.log("API error fixing biases: ", data);
        ToastNotification(
          "Something went wrong while fixing biases. Please try again."
        );
      }
      if (output_text === text) {
        ToastNotification("No changes were made to the text.");
      }
      setText(output_text);
      setBiases([]);
    } catch (error) {
      console.log("Error fixing biases: ", error);
      ToastNotification(
        "Something went wrong while fixing biases. Please try again."
      );
    }
    setLoading(false);
  };

  const expandTextHandler = async () => {
    try {
      setLoading(true);
      const data = await HttpPostRequest(
        {
          input_text: text,
        },
        "expand_text"
      );

      if (data.status === "success") {
        if (data.data.output_text === text) {
          ToastNotification("No changes were made to the text.");
        }
        setText(data.data.output_text);
      } else {
        console.log("API error lengthening text: ", data);
        ToastNotification(
          "Something went wrong while lengthening the text. Please try again later."
        );
      }
    } catch (error) {
      console.log("Error lengthening text: ", error);
      ToastNotification(
        "Something went wrong while lengthening the text. Please try again later."
      );
    }
    setLoading(false);
  };

  const shortenTextHandler = async () => {
    try {
      setLoading(true);
      const data = await HttpPostRequest(
        {
          input_text: text,
        },
        "shorten_text"
      );
      if (data.status === "success") {
        if (data.data.output_text === text) {
          ToastNotification("No changes were made to the text.");
        }
        setText(data.data.output_text);
      } else {
        console.log("API error shortening text: ", data);
        ToastNotification(
          "Something went wrong while shortening the text. Please try again."
        );
      }
    } catch (error) {
      console.log("Error shortening text: ", error);
      ToastNotification(
        "Something went wrong while shortening the text. Please try again."
      );
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col w-full h-full space-y-2 bg-gray1 p-2 rounded-b-lg">
      <div className="flex flex-row items-center align-middle justify-between space-x-1 px-2 bg-gray1 h-[10%]">
        <div className="flex flex-row space-x-2">
          <HeaderDropdown
            title="Style"
            tooltip="Choose a writing style: 'Professional' for formality, 'Direct' for clarity, 'Casual' for relaxed tones, or 'None' to keep the style the same."
            initialValue={tone}
            onValueChange={setTone}
            radioItems={["None", "Casual", "Direct", "Professional"]}
          />

          <HeaderDropdown
            title="Tone"
            tooltip="Choose a tone: 'Negative' for pessimistic, 'Neutral' for unbiased, 'Positive' for optimistic, or 'None' to keep the tone the same."
            initialValue={style}
            onValueChange={setStyle}
            radioItems={["None", "Negative", "Neutral", "Positive"]}
          />
        </div>
        <div className="flex flex-col overflow-x-hidden">
          <p className="pt-2 pb-1 text-gray5 text-[18px] font-medium text-center">
            Refine
          </p>

          <div className="flex flex-row space-x-1 overflow-x-hidden">
            <ActionButton
              text="Lengthen"
              action={expandTextHandler}
              icon={<MdExpand />}
              tooltip="Let AI elaborate on this text, while maintaining the same tone and style"
            />

            <ActionButton
              text="Shorten"
              action={shortenTextHandler}
              icon={<MdCompress />}
              tooltip="Let AI help you say more with less."
            />

            {biases.length > 0 ? (
              <ActionButton
                text="Fix Biases"
                action={fixBiasesHandler}
                icon={<MdAutoFixHigh />}
                tooltip="Resolve all identified biases."
              />
            ) : (
              <ActionButton
                text="Check Biases"
                action={checkBiasesHandler}
                icon={<MdFindInPage />}
                tooltip="Identify potential biases."
              />
            )}

            <CustomActionButton
              inputText={text}
              setText={setText}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
      <div className="grow w-full h-[90%] overflow-y-auto relative">
        {loading && (
          <div className="absolute top-50 left-50 w-full h-full">
            <LoadingSpinner />
          </div>
        )}
        <textarea
          autoFocus
          id="input-textarea"
          className="block h-full w-full px-2 text-[22px] font-poppins text-black bg-white rounded-lg border-0 focus:outline-none resize-none selection:bg-royal
          selection:bg-opacity-30"
          placeholder="Type or paste notes..."
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
      </div>
      <div className="px-2 flex flex-row bg-gray1 rounded-b-lg h-[6%] justify-end">
        <ButtonTooltip text={submitButtonTooltip()}>
          <button
            disabled={
              categoryReview === text &&
              tone.toLowerCase() === "none" &&
              style.toLowerCase() === "none"
            }
            onClick={onSubmit}
            type="submit"
            className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal float-right"
          >
            {tone.toLowerCase() === "none" && style.toLowerCase() === "none"
              ? "Save"
              : "Rephrase"}
          </button>
        </ButtonTooltip>
      </div>
    </div>
  );
}

export default InputTextArea;
