import { useState, useRef, useContext } from "react";
import BotContext from "../../context/BotContext";
import { HttpPostRequest } from "../../helpers/HttpPostRequest";
import ButtonTooltip from "../ui/ButtonTooltip";
import { ToastNotification } from "../ui/ToastNotifications";
import React from "react";

function CustomActionButton() {
  const {
    outputLoadingHandler,
    optimizedText,
    optimizedTextHandler,
    refineButtonsAnalytics,
  } = useContext(BotContext);

  const [customAction, setCustomAction] = useState("");
  const inputRef = useRef(null);

  function customActionHandler(event) {
    setCustomAction(event.target.value);
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      outputLoadingHandler("Running custom action...", true);
      try {
        setCustomAction("");
        inputRef.current.blur();

        const data = await HttpPostRequest(
          {
            input_text: optimizedText,
            custom_action: customAction,
          },
          "custom_text_action"
        );

        if (data.status === "success") {
          if (data.data.output_text === optimizedText) {
            ToastNotification("No changes were made to the text.");
          }
          optimizedTextHandler(data.data.output_text);
          await refineButtonsAnalytics(data.data.output_text);
        } else {
          console.log("API error running the custom action: ", data);
          ToastNotification(
            "Something went wrong while running the custom action. Please try again."
          );
        }
      } catch (error) {
        console.log("Error running the custom action: ", error);
        ToastNotification(
          "Something went wrong while running the custom action. Please try again."
        );
      }
      outputLoadingHandler("", false);
    }
  };

  return (
    <ButtonTooltip text="Perform advanced tasks with AI capabilities. Execute custom actions like providing instructions, improvement tips, and personalized customization options.">
      <input
        disabled={optimizedText === ""}
        className="flex w-[70px] lg:w-[150px] 2xl:min-w-[210px] whitespace-nowrap text-clip overflow-hidden px-1 md:px-3 py-[3px] text-[15px] placeholder:text-white placeholder:italic placeholder:font-poppins bg-royal hover:bg-deeproyal focus:bg-deeproyal rounded-lg text-white font-poppins"
        placeholder="Custom ('Translate to...')"
        value={customAction}
        onChange={customActionHandler}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        id="custom-action-input"
      ></input>
    </ButtonTooltip>
  );
}

export default CustomActionButton;
