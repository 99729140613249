import InputNotes from "./InputNotes";
import InputChat from "./InputChat";
import InputTextArea from "./InputTextArea";
import InputFooter from "./InputFooter";
import InputHeader from "./InputHeader";
import React from "react";
import PropTypes from "prop-types";

function InputTabLayout({ inputMode }) {
  return (
    <div className="flex flex-col w-full h-full">
      <InputHeader />
      <div className="px-2 md:pl-8 md:pr-2 pt-2 bg-gray1">
        <div className="flex h-[420px] md:h-[525px] w-full">
          {inputMode === "Notes" && <InputNotes />}
          {inputMode === "Free-Hand" && <InputTextArea />}
          {inputMode === "Chat" && <InputChat />}
        </div>
      </div>
      <InputFooter />
    </div>
  );
}

InputTabLayout.propTypes = {
  inputMode: PropTypes.string.isRequired,
};

export default InputTabLayout;
