import React from "react";

function LoadingSpinner() {
  return (
    <div className="pointer-events-none h-full w-full relative">
      <div className="bg-transparent absolute top-1/2 left-1/2 z-50">
        <div className=" text-royal h-9 w-9 animate-spin rounded-full border-4 border-solid border-current border-r-transparent"></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
