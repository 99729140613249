import { createContext, useState } from "react";
import React from "react";
import PropTypes from "prop-types";
import GetUserId from "../helpers/GetUserId";
import { v4 as uuidv4 } from "uuid";
import {
  PrimarySections,
  InputModes,
  AiCompanionStatuses,
} from "../constants/Constants";
import useToggle from "../hooks/useToggle";
import { GetPersonalizedCategoryDetails } from "../helpers/GetPersonalizedCategoryDetails";

const ReviewContext = createContext();
export const userId = GetUserId();

export function ReviewContextProvider({ children }) {
  const [currentStep, setCurrentStep] = useState(
    PrimarySections.ReviewInformation
  );
  const [inputMode, setInputMode] = useState(InputModes.Notes);
  const [expandCopilot, expandCopilotHandler] = useToggle(false);
  const [aiCompanionMessages, setAiCompanionMessages] = useState([]);
  const [aiCompanionStatus, setAiCompanionStatus] = useState(
    AiCompanionStatuses.Idle
  );
  const [templateDetails, setTemplateDetails] = useState();
  const [revieweeName, setRevieweeName] = useState("");
  const [revieweeJobTitle, setRevieweeJobTitle] = useState("");
  const [reviewCategories, setReviewCategories] = useState({});
  const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [copilotLoading, copilotLoadingHandler] = useToggle(false);
  const [mainSectionLoading, mainSectionLoadingHandler] = useToggle(false);
  const [categoryDetails, setCategoryDetails] = useState();
  const [categoryAnswers, setCategoryAnswers] = useState();
  const [categoryReview, setCategoryReview] = useState();
  const [categoryReviewScore, setCategoryReviewScore] = useState();
  const [categoryCurrentQuestionIndex, setCategoryCurrentQuestionIndex] =
    useState(0);
  const [categoryAssistantMessages, setCategoryAssistantMessages] = useState(
    []
  );
  const [categoryAssistantThreadId, setCategoryAssistantThreadId] = useState(
    uuidv4()
  );
  const [categoryAssistantTyping, categoryAssistantTypingHandler] =
    useToggle(false);

  function inputModeHandler(mode) {
    setInputMode(mode);
  }

  function currentStepHandler(step) {
    setCurrentStep(step);
  }

  function selectedCategoryNameHandler(name) {
    setSelectedCategoryName(name);
  }

  function templateDetailsHandler(details) {
    setTemplateDetails(details);
  }

  function revieweeNameHandler(name) {
    setRevieweeName(name);
  }

  function revieweeJobTitleHandler(jobTitle) {
    setRevieweeJobTitle(jobTitle);
  }

  async function categoryDetailsHandler(categoryName) {
    copilotLoadingHandler(true);
    mainSectionLoadingHandler(true);

    let newCategoryAnswers = [];
    let newCategoryDetails = [];

    if (reviewCategories[categoryName].categoryDetails === null) {
      newCategoryDetails =
        templateDetails.categories[reviewCategories[categoryName].index];

      let personalizedDetails = await GetPersonalizedCategoryDetails(
        newCategoryDetails,
        revieweeName,
        revieweeJobTitle
      );

      personalizedDetails.questions.push({
        question: "Additional Comments",
        answers: [],
      });

      newCategoryDetails = {
        ...newCategoryDetails,
        personalizedDetails: personalizedDetails,
      };

      newCategoryDetails.personalizedDetails.questions.forEach((question) => {
        newCategoryAnswers.push({
          question: question.question,
          answer: null,
        });
      });

      reviewCategories[categoryName].categoryDetails = newCategoryDetails;
      reviewCategories[categoryName].categoryAnswers = newCategoryAnswers;
    } else {
      newCategoryDetails = reviewCategories[categoryName].categoryDetails;
      newCategoryAnswers = reviewCategories[categoryName].categoryAnswers;
    }

    setCategoryAnswers(newCategoryAnswers);
    setCategoryDetails(newCategoryDetails);

    copilotLoadingHandler(false);
    mainSectionLoadingHandler(false);

    aiCompanionMessagesHandler({
      role: "assistant",
      content: newCategoryDetails.personalizedDetails.description,
    });
  }

  function categoryAnswersHandler(index, question, answer) {
    let newCategoryAnswers = [...categoryAnswers];
    newCategoryAnswers[index] = {
      question: question,
      answer: answer,
    };
    setCategoryAnswers(newCategoryAnswers);
    if (index < categoryDetails.personalizedDetails.questions.length) {
      setCategoryCurrentQuestionIndex(index + 1);
    }
  }

  function aiCompanionMessagesHandler(message) {
    setAiCompanionMessages((aiCompanionMessages) => [
      ...aiCompanionMessages,
      message,
    ]);
  }

  function categoryReviewHandler(review) {
    setCategoryReview(review);
  }

  function categoryReviewScoreHandler(score) {
    setCategoryReviewScore(score);
  }

  function aiCompanionStatusHandler(status) {
    setAiCompanionStatus(status);
  }

  function AddReviewMessageHandler(content) {
    aiCompanionMessagesHandler({
      role: "assistant",
      content: content,
      type: "review",
    });

    expandCopilotHandler(true);
  }

  function reviewCategoriesHandler(categories) {
    setReviewCategories(categories);
  }

  function categoryAssistantMessagesHandler(message) {
    setCategoryAssistantMessages((categoryAssistantMessages) => [
      ...categoryAssistantMessages,
      message,
    ]);
  }

  function categoryAssistantThreadIdHandler() {
    setCategoryAssistantThreadId(uuidv4());
  }

  function categoryAssistantReset() {
    setCategoryAssistantMessages([]);
    categoryAssistantThreadIdHandler();
    categoryAssistantTypingHandler(false);
  }

  function completeCategory(categoryName) {
    reviewCategories[categoryName].categoryReview = categoryReview;
    reviewCategories[categoryName].categoryReviewScore = categoryReviewScore;
    reviewCategories[categoryName].categoryDetails = categoryDetails;
    reviewCategories[categoryName].categoryAnswers = categoryAnswers;
    reviewCategories[categoryName].categoryAssistantMessages =
      categoryAssistantMessages;
    reviewCategories[categoryName].categoryAssistantThreadId =
      categoryAssistantThreadId;

    categoryReviewHandler(undefined);
    categoryReviewScoreHandler(undefined);
    setCategoryDetails(undefined);
    setCategoryAnswers([]);
    setCategoryAssistantMessages([]);
    categoryAssistantThreadIdHandler();
    setCategoryCurrentQuestionIndex(0);
  }

  function completeReview() {
    setInputMode(InputModes.Notes);
    expandCopilotHandler(false);
    setAiCompanionMessages([]);
    setAiCompanionMessages(AiCompanionStatuses.Idle);
    setTemplateDetails(undefined);
    setRevieweeName("");
    setRevieweeJobTitle("");
    setReviewCategories({});
    setSelectedCategoryName(undefined);
    categoryReviewHandler(undefined);
    categoryReviewScoreHandler(undefined);
    setCategoryDetails(undefined);
    setCategoryAnswers([]);
    setCategoryAssistantMessages([]);
    categoryAssistantThreadIdHandler();
    setCategoryCurrentQuestionIndex(0);
    setCurrentStep(PrimarySections.ReviewInformation);
  }

  return (
    <ReviewContext.Provider
      value={{
        inputMode,
        inputModeHandler,
        expandCopilot,
        expandCopilotHandler,
        aiCompanionMessages,
        aiCompanionMessagesHandler,
        aiCompanionStatus,
        aiCompanionStatusHandler,
        currentStep,
        currentStepHandler,
        templateDetails,
        templateDetailsHandler,
        revieweeName,
        revieweeNameHandler,
        revieweeJobTitle,
        revieweeJobTitleHandler,
        reviewCategories,
        reviewCategoriesHandler,
        selectedCategoryName,
        selectedCategoryNameHandler,
        completeCategory,
        completeReview,
        categoryDetails,
        categoryDetailsHandler,
        categoryAnswers,
        categoryAnswersHandler,
        categoryReview,
        categoryReviewHandler,
        categoryReviewScore,
        categoryReviewScoreHandler,
        categoryCurrentQuestionIndex,
        categoryAssistantMessages,
        categoryAssistantMessagesHandler,
        categoryAssistantThreadId,
        categoryAssistantThreadIdHandler,
        categoryAssistantTyping,
        categoryAssistantTypingHandler,
        categoryAssistantReset,
        copilotLoading,
        copilotLoadingHandler,
        mainSectionLoading,
        mainSectionLoadingHandler,
        AddReviewMessageHandler,
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
}

ReviewContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReviewContext;
