import React from "react";
import ButtonTooltip from "../../ui/ButtonTooltip";
import { useContext } from "react";
import ReviewContext from "../../../context/ReviewContext";
import IdentifiedSkills from "../analytics/IdentifiedSkills";
import EvaluationChart from "../analytics/EvaluationChart";
import useToggle from "../../../hooks/useToggle";

function ReviewAnalytics() {
  const { completeReview } = useContext(ReviewContext);
  const [skillsView, setSkillsView] = useToggle(true);

  return (
    <div className="flex flex-col h-full w-full bg-gray1 p-2 rounded-lg">
      <div className="flex flex-col space-y-2 w-full h-[94%] font-poppins">
        <div className="flex flex-col space-y-2 w-full h-[94%] font-poppins">
          <div className="flex flex-row space-x-4 w-full h-[6%] px-1 text-base align-bottom justify-center">
            <button
              onClick={() => setSkillsView(true)}
              className={`text-center font-medium border-b-[2px] ${
                skillsView && "text-lg border-royal"
              }`}
              disabled={skillsView}
            >
              Identified Skills
            </button>
            <button
              onClick={() => setSkillsView(false)}
              className={`text-center font-medium border-b-[2px] ${
                !skillsView && "text-lg border-royal"
              }`}
              disabled={!skillsView}
            >
              Employee Rating
            </button>
          </div>
          {skillsView ? <IdentifiedSkills /> : <EvaluationChart />}
        </div>
      </div>
      <div className="flex w-full h-[6%] flex-row items-center justify-end">
        <ButtonTooltip text={"Click to submit the review."}>
          <button
            onClick={() => {
              completeReview();
            }}
            className="h-full text-[15px] px-4 py-[4px] text-center flex items-center text-white font-poppins bg-royal rounded-full hover:bg-deeproyal"
          >
            Submit
          </button>
        </ButtonTooltip>
      </div>
    </div>
  );
}

export default ReviewAnalytics;
