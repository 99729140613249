import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../ui/LoadingSpinner";
import { useContext } from "react";
import ReviewContext from "../../context/ReviewContext";
import ButtonTooltip from "../ui/ButtonTooltip";
import { RiExpandLeftFill, RiExpandRightFill } from "react-icons/ri";

function CopilotLayout({ title, body, isLoading }) {
  const { expandCopilot, expandCopilotHandler } = useContext(ReviewContext);

  return (
    <div
      className={`flex flex-col ${
        expandCopilot ? "w-[65%]" : "w-[25%]"
      } min-h-full max-h-full space-y-2 rounded-lg bg-white p-2 text-black font-poppins`}
    >
      <div className="flex flex-row max-h-[10%] align-middle items-top justify-center">
        <ButtonTooltip text={expandCopilot ? "Collapse" : "Expand"}>
          <button
            className="text-[24px] text-royal hover:text-deeproyal mr-auto"
            onClick={() => expandCopilotHandler(!expandCopilot)}
          >
            {expandCopilot ? <RiExpandRightFill /> : <RiExpandLeftFill />}
          </button>
        </ButtonTooltip>
        <span className="text-[24px] text-center items-center flex-1">
          {title}
        </span>
      </div>

      <div className="flex min-w-full h-[90%] flex-1 rounded-lg">
        {isLoading ? <LoadingSpinner /> : body}
      </div>
    </div>
  );
}

CopilotLayout.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CopilotLayout;
