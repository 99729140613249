import * as Dialog from "@radix-ui/react-dialog";
import ButtonTooltip from "../ui/ButtonTooltip";
import { useState } from "react";
import { HttpPostRequest } from "../../helpers/HttpPostRequest";
import { ToastNotification } from "../ui/ToastNotifications";
import PropTypes from "prop-types";
import React from "react";

function FeedbackPopup() {
  const questionOne =
    "What did you like about the demo and what's missing in the tool(s) you currently use? Were there any game-changers?";
  const questionTwo =
    "What's missing? Why wouldn't you replace your existing tools with something like this?";
  const questionThree =
    "Did the demo inspire thoughts of other features or solutions, even if they are unrelated to employee feedback?";
  const emailPrompt = "Can we email you for more feedback?";

  const [questionOneAnswer, setQuestionOneAnswer] = useState("");
  const [questionTwoAnswer, setQuestionTwoAnswer] = useState("");
  const [questionThreeAnswer, setQuestionThreeAnswer] = useState("");
  const [emailAnswer, setEmailAnswer] = useState("");

  function questionOneAnswerHandler(value) {
    setQuestionOneAnswer(value);
  }

  function questionTwoAnswerHandler(value) {
    setQuestionTwoAnswer(value);
  }

  function questionThreeAnswerHandler(value) {
    setQuestionThreeAnswer(value);
  }

  function emailAnswerHandler(value) {
    setEmailAnswer(value);
  }

  function resetForm() {
    setQuestionOneAnswer("");
    setQuestionTwoAnswer("");
    setQuestionThreeAnswer("");
    setEmailAnswer("");
  }

  const onSubmit = async () => {
    try {
      const data = await HttpPostRequest(
        {
          question_one: questionOne,
          question_one_answer: questionOneAnswer.trim(),
          question_two: questionTwo,
          question_two_answer: questionTwoAnswer.trim(),
          question_three: questionThree,
          question_three_answer: questionThreeAnswer.trim(),
          email_prompt: emailPrompt,
          email_answer: emailAnswer.trim(),
        },
        "save_user_feedback"
      );

      if (data.status === "success") {
        ToastNotification("Thank you for your feedback!");
      } else {
        console.log("API error submitting feedback: ", data);
        ToastNotification(
          "Something went wrong while submitting feedback. Please try again."
        );
      }

      resetForm();
    } catch (error) {
      console.log("Error submitting feedback: ", error);
      ToastNotification(
        "Something went wrong while submitting feedback. Please try again."
      );
    }
  };

  const onCancel = () => {
    resetForm();
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <div>
          <ButtonTooltip text="Click to provide feedback.">
            <button className="block whitespace-nowrap p-[7px] md:p-3 px-2 md:px-8 text-[19px] text-white font-poppins bg-royal rounded-full baseline hover:bg-deeproyal">
              <p>Provide Feedback</p>
            </button>
          </ButtonTooltip>
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
          className="data-[state=open]:animate-contentShow fixed flex flex-col space-y-4 top-[50%] left-[50%] h-full w-full md:h-auto md:max-h-[60%] md:w-[80%] xl:w-[50%] overflow-y-auto translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white px-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
        >
          <Dialog.Title className="font-poppins text-3xl text-center my-[10px]">
            Provide Feedback
          </Dialog.Title>
          <form
            id="UserFeedbackForm"
            className="my-1 w-full font-poppins overflow-y-auto relative flex px-2 flex-col space-y-3"
          >
            <QuestionAnswerElement
              focus={true}
              question={questionOne}
              id={1}
              answer={questionOneAnswer}
              answerHandler={questionOneAnswerHandler}
            />
            <QuestionAnswerElement
              focus={false}
              question={questionTwo}
              id={2}
              answer={questionTwoAnswer}
              answerHandler={questionTwoAnswerHandler}
            />
            <QuestionAnswerElement
              focus={false}
              question={questionThree}
              id={3}
              answer={questionThreeAnswer}
              answerHandler={questionThreeAnswerHandler}
            />

            <div className="flex flex-col space-y-1">
              <p className="text-[20px] font-poppins text-black bg-white">
                {emailPrompt}
              </p>
              <textarea
                id="editor"
                className="w-full text-[18px] font-poppins text-black bg-white border-2 border-gray2 focus:outline-none resize-none selection:bg-royal
        selection:bg-opacity-30"
                rows="1"
                placeholder="Enter email..."
                value={emailAnswer}
                onChange={(e) => emailAnswerHandler(e.target.value)}
              />
            </div>
          </form>

          <div className="flex justify-between mt-3">
            <ButtonTooltip text="Click to submit feedback.">
              <Dialog.Close asChild>
                <button
                  type="submit"
                  onClick={onCancel}
                  className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
                >
                  Cancel
                </button>
              </Dialog.Close>
            </ButtonTooltip>

            <ButtonTooltip text="Click to save changes.">
              <Dialog.Close asChild>
                <button
                  disabled={
                    questionOneAnswer === "" &&
                    questionTwoAnswer === "" &&
                    questionThreeAnswer === "" &&
                    emailAnswer === ""
                  }
                  onClick={onSubmit}
                  type="submit"
                  className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
                >
                  Submit
                </button>
              </Dialog.Close>
            </ButtonTooltip>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function QuestionAnswerElement({ question, answer, answerHandler, id, focus }) {
  return (
    <div className="flex flex-col space-y-1">
      <p className="text-[20px] font-poppins text-black bg-white">{question}</p>
      <textarea
        autoFocus={focus}
        id={"editor" + id}
        className="w-full px-2 text-[18px] font-poppins text-black bg-white border-2 border-gray2 focus:outline-none resize-none selection:bg-royal
selection:bg-opacity-30 h-[100px]"
        placeholder="Type answer..."
        value={answer}
        onChange={(e) => answerHandler(e.target.value)}
      />
    </div>
  );
}

QuestionAnswerElement.propTypes = {
  question: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  answer: PropTypes.string.isRequired,
  answerHandler: PropTypes.func.isRequired,
  focus: PropTypes.bool.isRequired,
};

export default FeedbackPopup;
