import React from "react";
import PropTypes from "prop-types";
import { useEffect, useRef, useContext, useState } from "react";
import ChatMessage from "../../ui/ChatMessage";
import ReviewContext from "../../../context/ReviewContext";
import ButtonTooltip from "../../ui/ButtonTooltip";
import { HiDotsVertical } from "react-icons/hi";
import { AiCompanionStatuses } from "../../../constants/Constants";
import { PrimarySections, InputModes } from "../../../constants/Constants";

function AiCompanion() {
  const {
    categoryReview,
    categoryReviewScore,
    aiCompanionMessages,
    aiCompanionMessagesHandler,
    aiCompanionStatus,
    reviewCategories,
    completeCategory,
    selectedCategoryName,
    selectedCategoryNameHandler,
    expandCopilot,
    expandCopilotHandler,
    currentStepHandler,
    inputModeHandler,
  } = useContext(ReviewContext);

  const [incompleteCategories, setIncompleteCategories] = useState([]);
  const messagesRef = useRef();
  messagesRef.current = aiCompanionMessages;
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollTo(0, messagesEndRef.current?.scrollHeight);
  };

  useEffect(() => {
    scrollToBottom();
  }, [aiCompanionMessages, expandCopilot]);

  useEffect(() => {
    let remainingCategories = [];

    Object.keys(reviewCategories).map((category) => {
      if (category !== selectedCategoryName) {
        if (
          reviewCategories[category].categoryReview === null ||
          reviewCategories[category].categoryReviewScore === null
        ) {
          remainingCategories.push(category);
        }
      }
    });

    setIncompleteCategories(remainingCategories);
  }, [reviewCategories]);

  return (
    <div className="flex flex-col h-full w-full bg-gray1 p-2 rounded-lg">
      <div
        ref={messagesEndRef}
        className="flex flex-col space-y-3 w-full h-[91%] p-1 bg-white overflow-y-auto relative rounded-lg"
      >
        {aiCompanionMessages.map((message, index) => {
          return (
            <ChatMessage
              key={index}
              content={message.content}
              role={message.role}
              type={message.type}
            />
          );
        })}
      </div>

      <div className="h-[3%]">
        <p className="font-poppins text-[13px] text-center ">
          {aiCompanionStatus !== AiCompanionStatuses.Idle && aiCompanionStatus}
        </p>
      </div>
      <div
        className={`flex w-full h-[6%] flex-row items-center ${
          categoryReview?.length > 0 ? "justify-between" : "justify-end"
        }`}
      >
        <div className="flex flex-row space-x-2 h-full">
          {categoryReview?.length > 0 &&
            categoryReviewScore?.length > 0 &&
            (incompleteCategories.length === 0 ? (
              <ButtonTooltip text="Click to finalize the review.">
                <button
                  onClick={() => {
                    aiCompanionMessagesHandler({
                      role: "user",
                      content: "Finalize the review.",
                    });
                    completeCategory(selectedCategoryName);
                    expandCopilotHandler(false);
                    currentStepHandler(PrimarySections.FinalizeReview);
                  }}
                  className="h-full text-[15px] px-4 py-[4px] text-center flex items-center text-white font-poppins bg-royal rounded-full hover:bg-deeproyal"
                >
                  Finalize Review
                </button>
              </ButtonTooltip>
            ) : (
              <ButtonTooltip text={"Click to proceed to the next category."}>
                <button
                  onClick={() => {
                    aiCompanionMessagesHandler({
                      role: "user",
                      content: "Next category please.",
                    });
                    inputModeHandler(InputModes.Notes);
                    completeCategory(selectedCategoryName);
                    selectedCategoryNameHandler(incompleteCategories[0]);
                    expandCopilotHandler(false);
                  }}
                  className="h-full text-[15px] px-4 py-[4px] text-center flex items-center text-white font-poppins bg-royal rounded-full hover:bg-deeproyal"
                >
                  Next Category
                </button>
              </ButtonTooltip>
            ))}
        </div>
        <ButtonTooltip text={"Additional actions coming soon..."}>
          <button
            onClick={() => {}}
            className="h-full text-[24px] font-poppins text-royal rounded-full hover:text-deeproyal"
          >
            <HiDotsVertical />
          </button>
        </ButtonTooltip>
      </div>
    </div>
  );
}

AiCompanion.propTypes = {
  categoryDetails: PropTypes.object.isRequired,
};

export default AiCompanion;
