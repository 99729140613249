import { createContext, useState } from "react";
import useToggle from "../hooks/useToggle";
import GetUserId from "../helpers/GetUserId";
import { IdentifySkills, IndetifyBiases } from "../helpers/AnalyticsHelpers";
import { ToastNotification } from "../components/ui/ToastNotifications";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import PropTypes from "prop-types";

const BotContext = createContext();
export const userId = GetUserId();
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const homePageUrl = process.env.REACT_APP_HOME_PAGE_URL;
export const userGuideUrl = process.env.REACT_APP_USER_GUIDE_URL;

export function BotContextProvider({ children }) {
  const [optimizedText, setOptimizedText] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [chatThreadId, setChatThreadId] = useState(uuidv4());
  const [strengths, setStrengths] = useState([]);
  const [weaknesses, setWeaknesses] = useState([]);
  const [neutrals, setNeutrals] = useState([]);
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [tone, setTone] = useState("neutral");
  const [style, setStyle] = useState("professional");
  const [isOutputLoading, setIsOutputLoading] = useToggle(false);
  const [outputLoadingText, setOutputLoadingText] = useState("");
  const [inputMode, setInputMode] = useState("Free-Hand");
  const [outputView, setOutputView] = useState("Feedback");
  const [insightsView, setInsightsView] = useState("Evaluation");
  const [highlightNone, setHighlightNone] = useToggle(true);
  const [highlightBiases, setHighlightBiases] = useToggle(false);
  const [highlightSkills, setHighlightSkills] = useToggle(false);
  const [biasesToHighlight, setBiasesToHighlight] = useState([]);
  const [skillsToHighlight, setSkillsToHighlight] = useState([]);
  const [highlightedBiasText, setHighlightedBiasText] = useState("");
  const [highlightedSkillsText, setHighlightedSkillsText] = useState("");
  const [outputContextShowBiases, setOutputContextShowBiases] =
    useToggle(false);
  const [outputContextShowSkills, setOutputContextShowSkills] =
    useToggle(false);
  const [notesEditing, setNotesEditing] = useState(0);

  function biasesToHighlightHandler(values) {
    // console.log(`Biases to highlight: ${JSON.stringify(values)}`);
    setBiasesToHighlight([...values]);
  }

  function skillsToHighlightHandler(values) {
    // console.log(`Skills to highlight: ${JSON.stringify(values)}`);

    setSkillsToHighlight([...values]);
  }

  function highlightedBiasTextHandler(value) {
    setHighlightedBiasText(value);
  }

  function highlightedSkillsTextHandler(value) {
    setHighlightedSkillsText(value);
  }

  function optimizedTextHandler(value) {
    setOptimizedText(value);
  }

  function originalTextHandler(text) {
    setOriginalText(text);
  }

  function outputLoadingHandler(text, outputLoading) {
    setIsOutputLoading(outputLoading);
    setOutputLoadingText(text);
  }

  function updateToneHandler(toneValue) {
    // console.log("New tone value: ", toneValue);
    setTone(toneValue);
  }

  function updateStyleHandler(styleValue) {
    // console.log("New style value: ", styleValue);
    setStyle(styleValue);
  }

  function resetOutputContextAnalytics() {
    skillsToHighlightHandler([]);
    biasesToHighlightHandler([]);
    highlightedBiasTextHandler("");
    highlightedSkillsTextHandler("");
    setOutputContextShowBiases(false);
    setOutputContextShowSkills(false);
  }

  async function refineButtonsAnalytics(output_text) {
    try {
      if (outputContextShowBiases || outputContextShowSkills) {
        outputLoadingHandler("Analyzing...", true);
        resetOutputContextAnalytics();

        if (outputContextShowBiases && outputContextShowSkills) {
          setOutputContextShowBiases(true);
          setOutputContextShowSkills(true);
          const [skills, biases] = await Promise.all([
            IdentifySkills(output_text),
            IndetifyBiases(output_text),
          ]);

          if (skills.length > 0) {
            skillsToHighlightHandler(skills);
          }

          if (biases.length > 0) {
            biasesToHighlightHandler(biases);
          }
        } else if (outputContextShowBiases) {
          setOutputContextShowBiases(true);
          const biases = await IndetifyBiases(output_text);

          if (biases.length > 0) {
            biasesToHighlightHandler(biases);
          }
        } else if (outputContextShowSkills) {
          setOutputContextShowSkills(true);
          const skills = await IdentifySkills(output_text);
          if (skills.length > 0) {
            skillsToHighlightHandler(skills);
          }
        }
      }
    } catch (error) {
      console.log("Error gathering analytics: ", error);
      ToastNotification(
        "Something went wrong while gathering analytics. Please try again."
      );
    }
  }

  function notesListHandler(value) {
    setNotesList(value);
  }

  function addNoteHandler(value) {
    notesListHandler([...notesList, value]);
  }

  function removeNote(id) {
    setNotesList(notesList.filter((note) => note.id !== id));
  }

  function updateNote(id, noteValue, typeValue) {
    setNotesList(
      notesList.map((note) => {
        if (note.id === id) {
          note.type = typeValue;
          note.note = noteValue;
        }
        return note;
      })
    );
  }

  function chatReset() {
    setChatMessages([]);
    setChatThreadId(uuidv4());
    setIsAssistantTyping(false);
  }

  return (
    <BotContext.Provider
      value={{
        tone,
        style,
        isOutputLoading,
        outputLoadingText,
        outputLoadingHandler,
        inputMode,
        setInputMode,
        highlightNone,
        setHighlightNone,
        highlightBiases,
        setHighlightBiases,
        highlightSkills,
        setHighlightSkills,
        updateToneHandler,
        updateStyleHandler,
        optimizedText,
        optimizedTextHandler,
        originalText,
        originalTextHandler,
        biasesToHighlight,
        biasesToHighlightHandler,
        skillsToHighlight,
        skillsToHighlightHandler,
        highlightedBiasText,
        highlightedBiasTextHandler,
        highlightedSkillsText,
        highlightedSkillsTextHandler,
        outputContextShowBiases,
        setOutputContextShowBiases,
        outputContextShowSkills,
        setOutputContextShowSkills,
        resetOutputContextAnalytics,
        refineButtonsAnalytics,
        notesEditing,
        setNotesEditing,
        notesList,
        addNoteHandler,
        notesListHandler,
        removeNote,
        updateNote,
        chatMessages,
        setChatMessages,
        isAssistantTyping,
        setIsAssistantTyping,
        chatThreadId,
        setChatThreadId,
        chatReset,
        outputView,
        setOutputView,
        strengths,
        setStrengths,
        weaknesses,
        setWeaknesses,
        neutrals,
        setNeutrals,
        insightsView,
        setInsightsView,
      }}
    >
      {children}
    </BotContext.Provider>
  );
}

BotContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BotContext;
