import { HttpPostRequest } from "./HttpPostRequest";

export async function GetPersonalizedCategoryDetails(
  categoryDetails,
  revieweeName,
  revieweeJobTitle
) {
  let personalizedDescription = "";
  let personalizedQuestions = [];

  try {
    const descriptionData = await HttpPostRequest(
      {
        reviewee_name: revieweeName,
        reviewee_job_title: revieweeJobTitle,
        category_name: categoryDetails.name,
        category_description: categoryDetails.description,
      },
      "category_description_generator"
    );

    if (descriptionData.status === "success") {
      personalizedDescription = descriptionData.data.description;

      const questionData = await HttpPostRequest(
        {
          reviewee_name: revieweeName,
          reviewee_job_title: revieweeJobTitle,
          category_name: categoryDetails.name,
          category_description: personalizedDescription,
        },
        "category_questions_generator"
      );

      if (questionData.status === "success") {
        personalizedQuestions = questionData.data.questions;
      } else {
        console.log("Error getting category questions");
      }
    } else {
      console.log("Error getting category details");
    }
  } catch {
    console.log("Something went wrong when getting category details");
  }

  return {
    description: personalizedDescription,
    questions: personalizedQuestions,
  };
}
