import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../ui/LoadingSpinner";
import { useContext } from "react";
import ReviewContext from "../../context/ReviewContext";

function MainLayout({ title, body, isLoading }) {
  const { expandCopilot } = useContext(ReviewContext);

  return (
    <div
      className={`flex flex-col ${
        expandCopilot ? "w-[35%]" : "w-[75%]"
      } min-h-full max-h-full space-y-2 rounded-lg bg-white p-2 text-black font-poppins`}
    >
      <span className="text-[24px] max-h-[10%] text-center items-center ">
        {title}
      </span>

      <div className="flex min-w-full h-[90%] flex-1">
        {isLoading ? <LoadingSpinner /> : body}
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MainLayout;
