import { useContext } from "react";
import BotContext from "../../context/BotContext";
import React from "react";

function InputTextArea() {
  const { originalText, originalTextHandler } = useContext(BotContext);

  return (
    <textarea
      autoFocus
      id="input-textarea"
      className="block h-full w-full px-2 text-[22px] font-poppins text-black bg-white border-0 focus:outline-none resize-none selection:bg-royal
          selection:bg-opacity-30"
      placeholder="Type or paste notes..."
      value={originalText}
      onChange={(e) => originalTextHandler(e.target.value)}
    ></textarea>
  );
}

export default InputTextArea;
