import Xarrow from "react-xarrows";
import ButtonTooltip from "../ui/ButtonTooltip";
import React from "react";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import BotContext from "../../context/BotContext";
import { NineBoxEvaluation } from "../../helpers/AnalyticsHelpers";
import { ToastNotification } from "../ui/ToastNotifications";
import LoadingSpinner from "../ui/LoadingSpinner";
import useToggle from "../../hooks/useToggle";

function NineBoxEvaluationChart() {
  const { optimizedText, outputLoadingHandler } = useContext(BotContext);

  const [showOutput, setShowOutput] = useToggle(false);
  const [nineBoxScores, setNineBoxScores] = useState({
    poorPerformerScore: 0,
    averagePerformerScore: 0,
    solidPerformerScore: 0,
    inconsistentPerformerScore: 0,
    corePlayerScore: 0,
    highPerformerScore: 0,
    potentialGemScore: 0,
    greatPotentialScore: 0,
    starPerformerScore: 0,
  });

  useEffect(() => {
    if (optimizedText.length > 0) {
      GetNineBoxEvaluationScores();
    }
  }, []);

  async function GetNineBoxEvaluationScores() {
    try {
      outputLoadingHandler("Evaluating feedback...", true);
      await NineBoxEvaluation(optimizedText).then((response) => {
        setNineBoxScores({
          poorPerformerScore: response.poor_performer_score,
          averagePerformerScore: response.average_performer_score,
          solidPerformerScore: response.solid_performer_score,
          inconsistentPerformerScore: response.inconsistent_performer_score,
          corePlayerScore: response.core_player_score,
          highPerformerScore: response.high_performer_score,
          potentialGemScore: response.potential_gem_score,
          greatPotentialScore: response.great_potential_score,
          starPerformerScore: response.star_performer_score,
        });
      });
    } catch (error) {
      console.log("Error getting nine box evaluation: ", error);
      ToastNotification(
        "Something went wrong while getting the feedback evaluation. Please try again."
      );
    }
    outputLoadingHandler("", false);
    setShowOutput(true);
  }

  return (
    <div className="flex h-full w-full relative">
      {!showOutput && (
        <div className="absolute h-full w-full">
          <LoadingSpinner />
        </div>
      )}
      {showOutput && (
        <div
          id="NineBoxEvaluationChart"
          className="flex flex-row h-full w-full px-2 md:px-4 pt-4 pb-1 bg-white"
        >
          <div className="flex flex-row space-x-[2px] w-[6%] h-[92%]">
            <div className="flex h-full items-center align-middle">
              <p className="[writing-mode:vertical-lr] italic rotate-180 text-[16x] md:text-[22px] font-poppins text-center">
                Potential
              </p>
            </div>

            <div className="flex flex-col justify-between relative">
              <div id="top"></div>
              <div id="bottom"></div>
              <Xarrow
                start="bottom"
                end="top"
                strokeWidth={2}
                startAnchor="top"
                endAnchor="bottom"
                color="#223B89"
              />
            </div>
          </div>
          <div
            id="9_box_chart"
            className="flex flex-col space-y-1 h-full w-[92%] ml-4 md:ml-0"
          >
            <div className="flex flex-row space-x-1 w-full h-[32%] ml-[6px]">
              <CategoryBox
                name={"Potential\nGem"}
                tooltip="High Potential / Low Performance"
                categoryScore={nineBoxScores.potentialGemScore}
              ></CategoryBox>
              <CategoryBox
                name={"Great\nPotential"}
                tooltip="High Potential / Moderate Performance"
                categoryScore={nineBoxScores.greatPotentialScore}
              ></CategoryBox>
              <CategoryBox
                name={"Star\nPerformer"}
                tooltip="High Potential / High Performance"
                categoryScore={nineBoxScores.starPerformerScore}
              ></CategoryBox>
            </div>
            <div className="flex flex-row space-x-1 w-full h-[32%] ml-[6px]">
              <CategoryBox
                name={"Inconsistent\nPerformer"}
                tooltip="Moderate Potential / Low Performance"
                categoryScore={nineBoxScores.inconsistentPerformerScore}
              ></CategoryBox>
              <CategoryBox
                name={"Core\nPlayer"}
                tooltip="Moderate Potential / Moderate Performance"
                categoryScore={nineBoxScores.corePlayerScore}
              ></CategoryBox>
              <CategoryBox
                name={"High\nPerformer"}
                tooltip="Moderate Potential / High Performance"
                categoryScore={nineBoxScores.highPerformerScore}
              ></CategoryBox>
            </div>
            <div className="flex flex-row space-x-1 w-full h-[32%] ml-[6px]">
              <CategoryBox
                name={"Poor\nPerformer"}
                tooltip="Low Potential / Low Performance"
                categoryScore={nineBoxScores.poorPerformerScore}
              ></CategoryBox>
              <CategoryBox
                name={"Average\nPerformer"}
                tooltip="Low Potential / Moderate Performance"
                categoryScore={nineBoxScores.averagePerformerScore}
              ></CategoryBox>
              <CategoryBox
                name={"Solid\nPerformer"}
                tooltip="Low Potential / High Performance"
                categoryScore={nineBoxScores.solidPerformerScore}
              ></CategoryBox>
            </div>
            <div className="flex flex-col w-full space-y-[2px] pt-2">
              <div className="flex flex-row justify-between">
                <div id="left"></div>
                <div id="right"></div>
                <Xarrow
                  start="left"
                  end="right"
                  strokeWidth={2}
                  startAnchor="right"
                  endAnchor="left"
                  color="#223B89"
                />
              </div>

              <p className="text-[16x] md:text-[22px] italic font-poppins text-center">
                Performance
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function CategoryBox({ name, tooltip, categoryScore }) {
  let score = categoryScore;
  let bgOpacity = "";
  let textColor = "";

  if (score >= 50) {
    bgOpacity = "bg-opacity-90";
    textColor = "text-white";
  } else if (score >= 45 && score < 50) {
    bgOpacity = "bg-opacity-80";
    textColor = "text-white";
  } else if (score >= 40 && score < 45) {
    bgOpacity = "bg-opacity-70";
  } else if (score >= 35 && score <= 40) {
    bgOpacity = "bg-opacity-60";
    textColor = "text-white";
  } else if (score >= 30 && score <= 35) {
    textColor = "text-white";
    bgOpacity = "bg-opacity-50";
  } else if (score >= 25 && score < 30) {
    textColor = "text-black";
    bgOpacity = "bg-opacity-40";
    textColor = "text-black";
  } else if (score >= 20 && score < 25) {
    bgOpacity = "bg-opacity-30";
    textColor = "text-black";
  } else if (score >= 15 && score <= 20) {
    bgOpacity = "bg-opacity-25";
    textColor = "text-black";
  } else if (score >= 10 && score <= 15) {
    bgOpacity = "bg-opacity-20";
    textColor = "text-black";
  } else if (score >= 5 && score <= 10) {
    bgOpacity = "bg-opacity-10";
    textColor = "text-black";
  } else {
    bgOpacity = "bg-opacity-5";
    textColor = "text-black";
  }

  return (
    <ButtonTooltip text={tooltip} maxWidth={100}>
      <div
        className={`flex flex-col justify-around text-center font-poppins h-full w-[32%] bg-royal ${bgOpacity} ${textColor}`}
      >
        <span className="text-[15px] md:text-[17px] whitespace-pre-wrap">
          {name}
        </span>
        <span className="text-[11px] md:text-[13px]">{score}%</span>
      </div>
    </ButtonTooltip>
  );
}

CategoryBox.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  categoryScore: PropTypes.number.isRequired,
};

export default NineBoxEvaluationChart;
