import ButtonTooltip from "../ui/ButtonTooltip";
import React from "react";
import PropTypes from "prop-types";
import { MdDone } from "react-icons/md";
import { useContext } from "react";
import BotContext from "../../context/BotContext";

function DashboardButton({ value, text, tooltip }) {
  const { insightsView, setInsightsView, isOutputLoading } =
    useContext(BotContext);

  return (
    <ButtonTooltip text={tooltip}>
      <button
        disabled={isOutputLoading}
        onClick={() => setInsightsView(value)}
        className="whitespace-nowrap justify-center items-center flex flex-row space-x-1 text-[15px] px-3 py-[3px] bg-royal hover:bg-deeproyal rounded-lg text-white font-poppins"
      >
        {insightsView === value && (
          <div className="container w-[16px] h-full flex justify-center items-center">
            <MdDone />
          </div>
        )}
        <p>{text}</p>
      </button>
    </ButtonTooltip>
  );
}

DashboardButton.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default DashboardButton;
