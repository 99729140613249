import { useState, useContext, useRef, useEffect } from "react";
import ButtonTooltip from "../ui/ButtonTooltip";
import { MdModeEditOutline, MdDone, MdOutlineClose } from "react-icons/md";
import NoteTypeDropdown from "./NoteTypeDropdown";
import BotContext from "../../context/BotContext";
import React from "react";
import PropTypes from "prop-types";

function NoteItem({ noteData, updateNote, removeNote }) {
  const { notesEditing, setNotesEditing } = useContext(BotContext);

  const [noteText, setNoteText] = useState(noteData.note);
  const [noteType, setNoteType] = useState(noteData.type);
  const [disableEdit, setDisableEdit] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!disableEdit) {
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      );
    }
  }, [disableEdit, inputRef]);

  function noteTextHandler(value) {
    setNoteText(value);
  }

  return (
    <div
      key={noteData.id}
      className="flex bg-white font-poppins text-gray5 flex-col py-[9px] w-full space-y-1 border-b-[8px] border-gray1"
    >
      <textarea
        ref={inputRef}
        rows={2}
        disabled={disableEdit}
        value={noteText}
        onChange={(e) => {
          noteTextHandler(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (noteText.length !== 0) {
              e.preventDefault();
              updateNote(noteData.id, noteText, noteType);
              setDisableEdit(true);
            }
          }
        }}
        className="block w-full px-2 text-[22px] font-poppins text-black bg-white border-0 focus:outline-none resize-none selection:bg-royal
      selection:bg-opacity-30"
      ></textarea>

      <div className="flex flex-row justify-between w-full float-right px-2">
        {disableEdit ? (
          <NoteType noteType={noteData.type} />
        ) : (
          <NoteTypeDropdown
            noteType={noteType}
            valueChangeHandler={setNoteType}
          />
        )}

        {disableEdit ? (
          <ButtonTooltip text="Click to edit the feedback note.">
            <button
              onClick={() => {
                setNotesEditing(notesEditing + 1);
                setDisableEdit(false);
              }}
            >
              <MdModeEditOutline className="h-[30px] w-[30px] text-royal hover:text-deeproyal" />
            </button>
          </ButtonTooltip>
        ) : (
          <div className="flex flex-row space-x-2">
            <ButtonTooltip text="Click to remove the feedback note.">
              <button
                onClick={() => {
                  setNotesEditing(notesEditing - 1);
                  removeNote(noteData.id);
                }}
              >
                <MdOutlineClose className="h-[30px] w-[30px] text-red-500 hover:text-red-700" />
              </button>
            </ButtonTooltip>
            <ButtonTooltip text="Click to save the updated feedback note.">
              <button
                disabled={noteText.length === 0}
                onClick={() => {
                  if (noteText.length !== 0) {
                    setNotesEditing(notesEditing - 1);
                    updateNote(noteData.id, noteText, noteType);
                    setDisableEdit(true);
                  }
                }}
              >
                <MdDone className="h-[30px] w-[30px] text-royal hover:text-deeproyal" />
              </button>
            </ButtonTooltip>
          </div>
        )}
      </div>
    </div>
  );
}

NoteItem.propTypes = {
  noteData: PropTypes.object.isRequired,
  updateNote: PropTypes.func.isRequired,
  removeNote: PropTypes.func.isRequired,
};

function NoteType({ noteType }) {
  return (
    <div className="block h-[28px] w-25 px-2 text-center text-[16px] font-poppins border-[2px] border-deeproyal text-deeproyal rounded-lg baseline">
      {noteType}
    </div>
  );
}

NoteType.propTypes = {
  noteType: PropTypes.string.isRequired,
};

export default NoteItem;
