import * as Dialog from "@radix-ui/react-dialog";
import EmailAddress from "../../components/ui/EmailAddress";
import ButtonTooltip from "./ButtonTooltip";
import React from "react";
import PropTypes from "prop-types";
import { HttpPostRequest } from "../../helpers/HttpPostRequest";
import { ToastNotification } from "./ToastNotifications";

function PrivacyPopup({ children }) {
  const emailTo = "privacyofficer@quadrillion.co";
  const emailSubject = "Privacy Policy";

  const eraseUsageData = async () => {
    try {
      const data = await HttpPostRequest({}, "delete_user_data");

      if (data.status === "success") {
        ToastNotification("Your data has been erased!");
      } else {
        console.log("API error erasing usage data: ", data);
        ToastNotification(
          "Something went wrong while erasing your data. Please try again."
        );
      }
    } catch (error) {
      console.log("Error erasing usage data: ", error);
      ToastNotification(
        "Something went wrong while erasing your data. Please try again."
      );
    }
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
          className="data-[state=open]:animate-contentShow fixed flex flex-col pb-2 top-[50%] left-[50%] h-full w-full md:h-auto md:max-h-[60%] md:w-[80%] xl:w-[50%] overflow-y-auto translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white px-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
        >
          <Dialog.Title className="font-poppins text-3xl text-center h-[5%] my-[10px]">
            Privacy Policy
          </Dialog.Title>
          <div className="my-[10px] max-h-[85%] font-poppins flex-col space-y-3 overflow-y-auto">
            <p>
              We take the security of your data very seriously. We use a variety
              of security measures to protect your data, including secure
              servers, encrypted data, and physical security measures. We may
              update this privacy policy from time to time. If we make any
              significant changes to this policy, by posting a notice on our
              website. If you have any questions about this privacy policy,
              please contact us at{" "}
              <EmailAddress emailTo={emailTo} emailSubject={emailSubject} />.
            </p>
            <div className="flex-col space-y-2">
              <h2 className="text-2xl">Usage Data Retention</h2>
              <p>
                We securely store your usage data, including your inputs and
                responses, in an anonymized format. This means that this data
                cannot be linked back to you, ensuring your privacy. This
                anonymous data is automatically purged from our systems after 12
                hours, further safeguarding your information. If you prefer to
                have your session data removed immediately, you can click on the
                &quot;Erase Usage Data&quot; button below, and we will securely
                erase it right away.
              </p>
            </div>
            <div className="flex-col space-y-2">
              <h2 className="text-2xl">Google Analytics</h2>
              <p>
                We collect data about your visits to our website using Google
                Analytics. This data includes your IP address, browser type,
                operating system, and the pages you visit on our website. We use
                this data to improve our website and to provide you with better
                service.
              </p>
              <p>
                We may also collect other information about you, such as your
                name, email address, and contact information. We collect this
                information when you contact us, subscribe to our newsletter, or
                participate in a survey. We use this information to communicate
                with you, to provide you with our services, and to improve our
                website. We will not share your data with third parties without
                your consent. You have the right to access, correct, delete, and
                object to the processing of your data. You can exercise these
                rights by contacting us at{" "}
                <EmailAddress emailTo={emailTo} emailSubject={emailSubject} />.
              </p>
            </div>
            <div className="flex-col space-y-2">
              <h2 className="text-2xl">Microsoft Clarity</h2>
              <p>
                We partner with Microsoft Clarity and Microsoft Advertising to
                capture how you use and interact with our website through
                behavioral metrics, heatmaps, and session replay to improve and
                market our products/services. Website usage data is captured
                using first and third-party cookies and other tracking
                technologies to determine the popularity of products/services
                and online activity. Additionally, we use this information for
                site optimization, fraud/security purposes, and advertising. For
                more information about how Microsoft collects and uses your
                data, visit the Microsoft Privacy Statement.
              </p>
            </div>
          </div>

          <div className="pt-[5px] flex flex-row justify-between">
            <ButtonTooltip text="Close Privacy Statement.">
              <Dialog.Close asChild>
                <button className="block h-[40px] w-25 p-2 px-5 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal">
                  Close
                </button>
              </Dialog.Close>
            </ButtonTooltip>
            <ButtonTooltip text="Immediately erase usage data.">
              <Dialog.Close asChild>
                <button
                  onClick={eraseUsageData}
                  className="block h-[40px] w-25 p-2 px-5 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
                >
                  Erase Usage Data
                </button>
              </Dialog.Close>
            </ButtonTooltip>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

PrivacyPopup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivacyPopup;
