import { useState, useEffect, useRef, useContext } from "react";
import React from "react";
import { MdSend } from "react-icons/md";
import BotContext from "../../context/BotContext";
import { HttpPostRequest } from "../../helpers/HttpPostRequest";
import { ToastNotification } from "../ui/ToastNotifications";
import { IdentifySkills, IndetifyBiases } from "../../helpers/AnalyticsHelpers";
import PropTypes from "prop-types";
import LoadingSpinner from "../ui/LoadingSpinner";
import useToggle from "../../hooks/useToggle";

function ChatWidget() {
  const {
    optimizedText,
    chatMessages,
    chatThreadId,
    setChatMessages,
    setIsAssistantTyping,
    userId,
    outputLoadingHandler,
    optimizedTextHandler,
    highlightNone,
    highlightBiases,
    highlightSkills,
    skillsToHighlightHandler,
    biasesToHighlightHandler,
    setOutputContextShowBiases,
    setOutputContextShowSkills,
    isAssistantTyping,
    resetOutputContextAnalytics,
    setOutputView,
    setInsightsView,
  } = useContext(BotContext);

  const [input, setInput] = useState("");
  const [isAssistantLoading, SetIsAssistantLoading] = useToggle(false);
  const messagesRef = useRef();
  messagesRef.current = chatMessages;
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const assistantMessageHandlerRef = useRef();
  assistantMessageHandlerRef.current = assistantMessageHandler;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollTo(0, messagesEndRef.current?.scrollHeight);
  };

  useEffect(() => {
    const assistantHandler = async (value) => {
      if (messagesRef.current.length === 0) {
        await assistantMessageHandlerRef.current(value, true);
      } else {
        await assistantMessageHandlerRef.current(value);
      }
    };

    if (messagesRef.current.length === 0) {
      assistantHandler("");
    } else {
      if (messagesRef.current.at(-1)?.role === "system") {
        outputLoadingHandler("Generating feedback...", true);
        assistantHandler("GENERATE FEEDBACK");
      }
    }
    scrollToBottom();
  }, [chatMessages]);

  useEffect(() => {
    !isAssistantTyping && inputRef.current?.focus();
  }, [isAssistantTyping, inputRef]);

  function addMessage(message) {
    setChatMessages([...messagesRef.current, message]);
  }

  async function userMessageHandler(content) {
    let message = { content: content, role: "user" };
    addMessage(message);
    setInput("");
    await assistantMessageHandler(content);
  }

  async function assistantMessageHandler(
    _userMessage,
    _firstMessage = false,
    _userId = userId,
    _chatThreadId = chatThreadId,
    _generatedFeedback = optimizedText,
    _highlightNone = highlightNone,
    _highlightBiases = highlightBiases,
    _highlightSkills = highlightSkills
  ) {
    setIsAssistantTyping(true);
    if (_firstMessage) {
      SetIsAssistantLoading(true);
    }

    let message = { content: "", role: "assistant" };

    if (_userMessage.toLowerCase() === "generate feedback") {
      _userMessage = _userMessage.toUpperCase();
    }

    try {
      const data = await HttpPostRequest(
        {
          user_message: _userMessage,
          user_id: _userId,
          thread_id: _chatThreadId,
        },
        "feedback_assistant"
      );

      if (data.status === "success") {
        if (data.data.response === "") {
          ToastNotification("Something went wrong. Please try again.");
          message.content = "Something went wrong. Please try again.";
        } else {
          message.content = data.data.response;

          if ("feedback" in data.data) {
            if (data.data.feedback.length > 0) {
              setOutputView("Feedback");
              setInsightsView("Evaluation");
              if (_generatedFeedback !== data.data.feedback) {
                resetOutputContextAnalytics();
                optimizedTextHandler(data.data.feedback);
                if (!_highlightNone) {
                  try {
                    outputLoadingHandler("Analyzing...", true);

                    if (_highlightBiases && _highlightSkills) {
                      const [skills, biases] = await Promise.all([
                        IdentifySkills(data.data.feedback),
                        IndetifyBiases(data.data.feedback),
                      ]);

                      if (skills.length > 0) {
                        skillsToHighlightHandler(skills);
                      }

                      if (biases.length > 0) {
                        biasesToHighlightHandler(biases);
                      }
                      setOutputContextShowBiases(true);
                      setOutputContextShowSkills(true);
                    } else if (_highlightBiases) {
                      const biases = await IndetifyBiases(data.data.feedback);
                      if (biases.length > 0) {
                        biasesToHighlightHandler(biases);
                      }
                      setOutputContextShowBiases(true);
                    } else if (_highlightSkills) {
                      const skills = await IdentifySkills(data.data.feedback);
                      if (skills.length > 0) {
                        skillsToHighlightHandler(skills);
                      }
                      setOutputContextShowSkills(true);
                    }
                  } catch (error) {
                    console.log("Error analyzing text: ", error);
                    ToastNotification(
                      "Something went wrong while gathering analytics. Please try again."
                    );
                  }
                }
                ToastNotification("Feedback successfully generated.");
              }
            }
          }
        }
      } else {
        console.log("API error with feedback assistant: ", data);
        if (data.message.includes("Oops!")) {
          message.content = data.message;
          ToastNotification(data.message);
        } else {
          message.content = "Something went wrong. Please try again.";
          ToastNotification(
            "Something went wrong with the feedback assistant. Please try again later."
          );
        }
      }
    } catch (error) {
      console.log("Error feedback assistant: ", error);
      message.content = "Something went wrong. Please try again.";
      ToastNotification(
        "Something went wrong with the feedback assistant. Please try again later."
      );
    }

    addMessage(message);
    setIsAssistantTyping(false);
    if (_firstMessage) {
      SetIsAssistantLoading(false);
    }
    outputLoadingHandler("", false);
  }

  return (
    <div className="flex flex-col h-full w-full p-2 bg-white">
      <div
        ref={messagesEndRef}
        className="flex w-full h-[90%] px-1 overflow-y-auto relative"
      >
        {isAssistantLoading ? (
          <div className="absolute h-full w-[90%]">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="flex w-full h-full flex-col space-y-3">
            {chatMessages.map((message, index) => {
              return (
                <Message
                  key={index}
                  content={message.content}
                  role={message.role}
                />
              );
            })}
          </div>
        )}
      </div>

      <div className="h-[5%] md:h-[3%]">
        <p className="font-poppins text-[12px] text-center ">
          {isAssistantTyping && "Assistant is typing..."}
        </p>
      </div>
      <div className="flex w-full h-[10%] flex-row border-[2px] border-gray2 items-center justify-center">
        <textarea
          ref={inputRef}
          autoFocus
          disabled={isAssistantTyping}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && input.length > 0) {
              e.preventDefault();
              userMessageHandler(input);
            }
          }}
          placeholder="Type a message..."
          className="w-[90%] h-[55%] font-poppins text-black bg-white border-0 focus:outline-none resize-none selection:bg-royal overflow-auto"
        ></textarea>
        <button
          disabled={input.length === 0}
          onClick={() => userMessageHandler(input)}
          className="flex items-center justify-center"
        >
          <MdSend className="h-[25px] w-[25px] text-royal hover:text-deeproyal" />
        </button>
      </div>
    </div>
  );
}

export default ChatWidget;

Message.propTypes = {
  content: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

function Message({ content, role }) {
  if (role === "assistant") {
    return (
      <div className="flex flex-row w-[60%] px-2 bg-gray1 rounded-tl-lg rounded-tr-lg rounded-br-lg py-2">
        <p className="w-full text-left text-[16px] font-poppins text-black">
          {content}
        </p>
      </div>
    );
  } else if (role === "user") {
    return (
      <div className="flex w-full justify-end">
        <div className="flex flex-row w-[60%] px-2 bg-royal rounded-tl-lg rounded-tr-lg rounded-bl-lg py-2 justify-end">
          <p className="w-full text-right text-[16px] font-poppins text-white">
            {content}
          </p>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
