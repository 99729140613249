import { useContext, useEffect, useRef } from "react";
import BotContext from "../../context/BotContext";
import { HighlightWords } from "../../helpers/HighlightWords";
import ButtonTooltip from "../ui/ButtonTooltip";
import OutputTabLayout from "./OutputTabLayout";
import React from "react";

function OutputLayout() {
  const {
    outputView,
    insightsView,
    setInsightsView,
    isOutputLoading,
    setOutputView,
    optimizedText,
    biasesToHighlight,
    skillsToHighlight,
    highlightSkills,
    highlightBiases,
  } = useContext(BotContext);

  const botRef = useRef(useContext(BotContext));
  const defaultTabClassName =
    "py-[5px] h-[75%] text-[17px] border-b-[1px] border-gray3";
  const selectedClassName = "rounded-t-lg py-2 h-full text-[18px]";

  useEffect(() => {
    if (biasesToHighlight.length > 0 && highlightBiases) {
      const highlightedBiases = HighlightWords(
        optimizedText,
        biasesToHighlight,
        "bg-darktangerine"
      );

      botRef.current.highlightedBiasTextHandler(highlightedBiases);
    }

    if (skillsToHighlight.length > 0 && highlightSkills) {
      let references = [];
      skillsToHighlight.map((skill) => references.push(skill.references));
      references = references.flat();

      const highlightedSkills = HighlightWords(
        optimizedText,
        references,
        "bg-magicmint"
      );

      botRef.current.highlightedSkillsTextHandler(highlightedSkills);
    }
  }, [
    biasesToHighlight,
    optimizedText,
    skillsToHighlight,
    highlightBiases,
    highlightSkills,
  ]);

  return (
    <div className="flex flex-col w-full md:w-1/2 h-full">
      <div className="flex w-[full] flex-row align-bottom space-x-[1px]">
        <button
          disabled={isOutputLoading}
          onClick={() => {
            setOutputView("Feedback");
            setInsightsView("Evaluation");
          }}
          className={`flex whitespace-nowrap mt-auto align-middle bg-gray1 px-2 md:px-5 font-poppins outline-none text-gray5 ${
            outputView === "Feedback" ? selectedClassName : defaultTabClassName
          }`}
        >
          <ButtonTooltip text="View the generated feedback.">
            <span>Feedback</span>
          </ButtonTooltip>
        </button>
        <button
          disabled={optimizedText.length === 0 || isOutputLoading}
          onClick={() => {
            setOutputView("Insights");
          }}
          className={`flex whitespace-nowrap mt-auto align-middle bg-gray1 px-2 md:px-5 font-poppins outline-none text-gray5 ${
            outputView === "Insights" ? selectedClassName : defaultTabClassName
          }`}
        >
          <ButtonTooltip text="View analytics about the feedback.">
            <span>Insights</span>
          </ButtonTooltip>
        </button>
      </div>
      <div className="flex h-full w-full">
        <OutputTabLayout outputView={outputView} insightsView={insightsView} />
      </div>
    </div>
  );
}

export default OutputLayout;
