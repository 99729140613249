import ReviewContext from "../context/ReviewContext";
import React from "react";
import { useContext, useState, useEffect } from "react";
import MainLayout from "../components/review-components/MainLayout";
import CopilotLayout from "../components/review-components/CopilotLayout";
import ReviewInformation from "../components/review-components/primary-sections/ReviewInformation";
import InputLayout from "../components/review-components/primary-sections/InputLayout";
import TemplateInformation from "../components/review-components/copilot-sections/TemplateInformation";
import FinalizeReview from "../components/review-components/primary-sections/FinalizeReview";
import AiCompanion from "../components/review-components/copilot-sections/AiCompanion";
import { PrimarySections } from "../constants/Constants";
import ReviewAnalytics from "../components/review-components/copilot-sections/ReviewAnalytics";
import useToggle from "../hooks/useToggle";

function PerformanceReviewPage() {
  const {
    currentStep,
    categoryDetails,
    categoryDetailsHandler,
    copilotLoading,
    mainSectionLoading,
    selectedCategoryName,
  } = useContext(ReviewContext);

  const [currentCategoryName, setCurrentCategoryName] = useState();
  const [isMobile, setIsMobile] = useToggle(false);

  useEffect(() => {
    const updateCategoryDetails = async (categoryName) => {
      await categoryDetailsHandler(categoryName);
    };

    if (currentStep !== PrimarySections.ReviewInformation) {
      if (currentCategoryName === undefined) {
        updateCategoryDetails(selectedCategoryName);
        setCurrentCategoryName(selectedCategoryName);
      } else if (currentCategoryName !== selectedCategoryName) {
        updateCategoryDetails(selectedCategoryName);
        setCurrentCategoryName(selectedCategoryName);
      }
    }
  }, [selectedCategoryName, currentStep]);

  useEffect(() => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);
  });

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  function renderComponents() {
    if (currentStep === PrimarySections.ReviewInformation) {
      return (
        <div className="flex flex-row space-x-2 min-h-full w-full bg-gray1 rounded-lg">
          <MainLayout
            title={currentStep}
            body={<ReviewInformation />}
            isLoading={mainSectionLoading}
          />
          <CopilotLayout
            title="Template Preview"
            body={<TemplateInformation />}
            isLoading={copilotLoading}
          />
        </div>
      );
    } else if (currentStep === PrimarySections.ReviewQuestions) {
      return (
        <div className="flex flex-row space-x-2 min-h-full w-full bg-gray1 rounded-lg">
          <MainLayout
            title={selectedCategoryName}
            body={
              categoryDetails === undefined ? (
                <div></div>
              ) : (
                <InputLayout categoryDetails={categoryDetails} />
              )
            }
            isLoading={mainSectionLoading}
          />
          <CopilotLayout
            title="AI Companion"
            body={
              categoryDetails === undefined ? (
                <div></div>
              ) : (
                <AiCompanion categoryDetails={categoryDetails} />
              )
            }
            isLoading={copilotLoading}
          />
        </div>
      );
    } else if (currentStep === PrimarySections.FinalizeReview) {
      return (
        <div className="flex flex-row space-x-2 min-h-full w-full bg-gray1 rounded-lg">
          <MainLayout
            title={currentStep}
            body={<FinalizeReview />}
            isLoading={mainSectionLoading}
          />
          <CopilotLayout
            title="Analytics"
            body={<ReviewAnalytics />}
            isLoading={copilotLoading}
          />
        </div>
      );
    }
  }

  return (
    <div className="bg-gray1 rounded-2xl w-[70%] min-h-full max-h-full mx-auto my-auto font-poppins min-w-[400px] p-4 flex flex-row space-x-2 grow">
      {isMobile ? (
        <div className="min-h-full w-full flex justify-center items-center">
          <p className="text-center italic text-gray5">
            Thank you for trying out our alpha release! We are working hard to
            make our app compatible with mobile devices, but for now, we
            recommend using a larger display for the best experience.
          </p>
        </div>
      ) : (
        renderComponents()
      )}
    </div>
  );
}

export default PerformanceReviewPage;
