import OutputLayout from "../components/bot-output/OutputLayout";
import InputLayout from "../components/bot-input/InputLayout";
import React from "react";

function HomePage() {
  return (
    <div className="h-full xl:w-[70%] xl:mx-auto my-auto font-poppins min-w-[400px] px-1 md:px-2 lg:px-0 flex flex-col space-y-4 md:flex-row md:space-x-0 md:space-y-0 flex-1">
      <InputLayout />
      <OutputLayout />
    </div>
  );
}

export default HomePage;
