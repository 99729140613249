import ButtonTooltip from "../ui/ButtonTooltip";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdDone, MdArrowDropDown } from "react-icons/md";
import React from "react";
import PropTypes from "prop-types";

function TypeDropdown({ noteType, valueChangeHandler }) {
  return (
    <div className="flex flex-row items-center justify-center space-x-[2px]">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <div>
            <ButtonTooltip text="Select a feedback type: Strength' for positive aspects, 'Challenge' for growth areas, or 'Overview' for general feedback.">
              <button className="flex-row w-[110px] h-[30px] capitalize justify-between text-white font-poppins bg-royal hover:bg-deeproyal focus:ring-1 focus:outline-none focus:ring-deeproyal font-medium rounded-lg text-[15px] px-2 py-[3px] text-center inline-flex items-center">
                {noteType}
                <MdArrowDropDown />
              </button>
            </ButtonTooltip>
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="w-[110px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform]"
            sideOffset={5}
          >
            <DropdownMenu.RadioGroup
              value={noteType}
              onValueChange={valueChangeHandler}
            >
              <RadioItem value="Strength" />
              <RadioItem value="Challenge" />
              <RadioItem value="Overview" />
            </DropdownMenu.RadioGroup>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
}

TypeDropdown.propTypes = {
  noteType: PropTypes.string.isRequired,
  valueChangeHandler: PropTypes.func.isRequired,
};

function RadioItem({ value }) {
  return (
    <DropdownMenu.RadioItem
      className="text-[15px] capitalize leading-none text-poppins rounded-[3px] flex items-center hover:bg-royal hover:text-white h-[25px] px-[5px] relative pl-[25px] select-none outline-none"
      value={value}
    >
      <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
        <MdDone />
      </DropdownMenu.ItemIndicator>
      {value}
    </DropdownMenu.RadioItem>
  );
}

RadioItem.propTypes = {
  value: PropTypes.string.isRequired,
};

export default TypeDropdown;
