import ActionButton from "./ActionButton";
import CustomActionButton from "./CustomActionButton";
import { MdExpand, MdCompress } from "react-icons/md";
import { useContext } from "react";
import BotContext from "../../context/BotContext";
import { HttpPostRequest } from "../../helpers/HttpPostRequest";
import BiasFixingPopup from "./BiasFixingPopup";
import { ToastNotification } from "../ui/ToastNotifications";
import React from "react";
import DashboardButton from "./DashboardButton";

function OutputHeader() {
  const {
    outputView,
    outputLoadingHandler,
    optimizedText,
    optimizedTextHandler,
    outputContextShowBiases,
    biasesToHighlight,
    refineButtonsAnalytics,
  } = useContext(BotContext);

  const expandTextHandler = async () => {
    outputLoadingHandler("Lengthening text...", true);
    try {
      const data = await HttpPostRequest(
        {
          input_text: optimizedText,
        },
        "expand_text"
      );

      if (data.status === "success") {
        if (data.data.output_text === optimizedText) {
          ToastNotification("No changes were made to the text.");
        }
        optimizedTextHandler(data.data.output_text);
        await refineButtonsAnalytics(data.data.output_text);
      } else {
        console.log("API error lengthening text: ", data);
        ToastNotification(
          "Something went wrong while lengthening the text. Please try again later."
        );
      }
    } catch (error) {
      console.log("Error lengthening text: ", error);
      ToastNotification(
        "Something went wrong while lengthening the text. Please try again later."
      );
    }
    outputLoadingHandler("", false);
  };

  const shortenTextHandler = async () => {
    outputLoadingHandler("Shortening text...", true);
    try {
      const data = await HttpPostRequest(
        {
          input_text: optimizedText,
        },
        "shorten_text"
      );
      if (data.status === "success") {
        if (data.data.output_text === optimizedText) {
          ToastNotification("No changes were made to the text.");
        }
        optimizedTextHandler(data.data.output_text);
        await refineButtonsAnalytics(data.data.output_text);
      } else {
        console.log("API error shortening text: ", data);
        ToastNotification(
          "Something went wrong while shortening the text. Please try again."
        );
      }
    } catch (error) {
      console.log("Error shortening text: ", error);
      ToastNotification(
        "Something went wrong while shortening the text. Please try again."
      );
    }
    outputLoadingHandler("", false);
  };

  function renderElement() {
    if (outputView === "Feedback") {
      return (
        <div className="flex flex-col justify-center align-center items-center w-full">
          <p className="py-1 md:pt-2 md:pb-1 text-gray5 text-[18px] font-medium">
            Refine
          </p>

          <div className="flex flex-row items-center space-x-1 lg:space-x-2">
            <ActionButton
              text="Lengthen"
              action={expandTextHandler}
              icon={<MdExpand />}
              disabled={optimizedText === ""}
              tooltip="Let AI elaborate on this text, while maintaining the same tone and style"
            />

            <ActionButton
              text="Shorten"
              action={shortenTextHandler}
              icon={<MdCompress />}
              disabled={optimizedText === ""}
              tooltip="Let AI help you say more with less."
            />
            {outputContextShowBiases && biasesToHighlight.length > 0 && (
              <BiasFixingPopup />
            )}

            <CustomActionButton />
          </div>
        </div>
      );
    } else if (outputView === "Insights") {
      return (
        <div className="flex flex-col justify-center align-center items-center w-full min-h-full">
          <p className="py-1 md:pt-2 md:pb-1 text-gray5 text-[18px] font-medium">
            Dashboards
          </p>

          <div className="flex items-center space-x-8">
            <DashboardButton
              value="Evaluation"
              text="Feedback Evaluation"
              tooltip="Click to view the 9-box model chart. This chart is a tool for employee assessment that helps to grade employee performance and potential."
            />

            <DashboardButton
              value="Skills"
              text="Identified Skills"
              tooltip="Click to view the identified skills and their categories."
            />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex items-center justify-between px-2 md:pl-4 md:pr-8 bg-gray1 h-[10%] w-full">
      <div className="flex h-full w-full flex-col items-center justify-center">
        {renderElement()}
      </div>
    </div>
  );
}

export default OutputHeader;
