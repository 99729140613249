import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { userId } from "../src/context/BotContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-RC6KDZBHT0");
const tagManagerArgs = {
  gtmId: "GTM-PZHPZK7Z",
  dataLayer: {
    user_id: userId,
  },
};

TagManager.initialize(tagManagerArgs);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
