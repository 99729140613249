export const PrimarySections = {
  ReviewInformation: "Review Information",
  ReviewQuestions: "Review Question",
  FinalizeReview: "Finalize Review",
  ReviewSubmission: "Review Submission",
};

export const InputModes = {
  AiInterview: "AI Interview",
  Notes: "Notes",
  Refine: "Refine",
};

export const AiCompanionStatuses = {
  Idle: "Idle",
  Generating: "Generating the review...",
  Refining: "Refining the review...",
  Scoring: "Scoring the review...",
};
