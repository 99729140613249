import { useState, useEffect } from "react";
import { MdDone, MdArrowDropDown } from "react-icons/md";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useContext } from "react";
import BotContext from "../../context/BotContext";
import ButtonTooltip from "../ui/ButtonTooltip";
import React from "react";
import PropTypes from "prop-types";

function HighlightDropdown() {
  const [lensText, setLensText] = useState("");
  const {
    highlightNone,
    setHighlightNone,
    highlightBiases,
    setHighlightBiases,
    highlightSkills,
    setHighlightSkills,
  } = useContext(BotContext);

  useEffect(() => {
    const selected = [];

    if (highlightNone) {
      setLensText("none");
    } else {
      if (highlightBiases) {
        selected.push("bias");
      }

      if (highlightSkills) {
        selected.push("skill");
      }
      setLensText(selected.join(", "));
    }
  }, [highlightNone, highlightBiases, highlightSkills]);

  return (
    <div className="flex flex-col items-center justify-center w-1/4">
      <div className="whitespace-nowrap py-1 md:pt-2 md:pb-1 h-full w-full flex flex-row space-x-1 align-middle justify-center">
        <p className="text-gray5 text-[18px] font-medium font-poppins">
          Highlight
        </p>
      </div>
      <div className="flex flex-row items-center justify-center space-x-[2px]">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <div>
              <ButtonTooltip text="Choose 'Bias' to highlight identified bias or 'Skills' to highlight recognized skills. You can select both if you want both types of highlights to be shown or 'None' if you do not want any highlighting.">
                <button className="flex-row min-w-[100px] 2xl:min-w-[140px] capitalize justify-between text-white font-poppins bg-royal hover:bg-deeproyal focus:ring-1 focus:outline-none focus:ring-deeproyal font-medium rounded-lg text-[15px] px-2 2xl:px-4 py-[3px] text-center inline-flex items-center">
                  <span className="whitespace-nowrap w-[70%] text-ellipsis overflow-hidden">
                    {lensText}
                  </span>
                  <MdArrowDropDown />
                </button>
              </ButtonTooltip>
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="min-w-[100px] 2xl:min-w-[150px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform]"
              sideOffset={5}
            >
              <CheckboxItem
                value="none"
                checked={highlightNone}
                changeHandler={(e) => {
                  setHighlightNone(e);
                  if (e) {
                    setHighlightBiases(false);
                    setHighlightSkills(false);
                  }
                }}
              />
              <CheckboxItem
                value="bias"
                checked={highlightBiases}
                changeHandler={(e) => {
                  setHighlightBiases(e);
                  if (e) {
                    setHighlightNone(false);
                  } else {
                    if (!highlightSkills) {
                      setHighlightNone(true);
                    }
                  }
                }}
              />
              <CheckboxItem
                value="skill"
                checked={highlightSkills}
                changeHandler={(e) => {
                  setHighlightSkills(e);
                  if (e) {
                    setHighlightNone(false);
                  } else {
                    if (!highlightBiases) {
                      setHighlightNone(true);
                    }
                  }
                }}
              />
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
}

CheckboxItem.propTypes = {
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func.isRequired,
};

function CheckboxItem({ value, checked, changeHandler }) {
  return (
    <DropdownMenu.CheckboxItem
      className="text-[15px] capitalize leading-none text-poppins rounded-[3px] flex items-center hover:bg-royal hover:text-white h-[25px] px-[5px] relative pl-[25px] select-none outline-none"
      value={value}
      checked={checked}
      onCheckedChange={(value) => {
        changeHandler(value);
      }}
    >
      <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
        <MdDone />
      </DropdownMenu.ItemIndicator>
      {value}
    </DropdownMenu.CheckboxItem>
  );
}

export default HighlightDropdown;
