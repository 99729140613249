import * as Dialog from "@radix-ui/react-dialog";
import { MdAutoFixHigh } from "react-icons/md";
import ButtonTooltip from "../ui/ButtonTooltip";
import BotContext from "../../context/BotContext";
import { useContext, useState } from "react";
import useToggle from "../../hooks/useToggle";
import LoadingSpinner from "../ui/LoadingSpinner";
import { HttpPostRequest } from "../../helpers/HttpPostRequest";
import { ToastNotification } from "../ui/ToastNotifications";
import React from "react";

function BiasFixingPopup() {
  const {
    optimizedText,
    optimizedTextHandler,
    biasesToHighlight,
    setOutputContextShowBiases,
  } = useContext(BotContext);

  const [fixedBiasTextValue, setFixedBiasTextValue] = useState("");
  const [isLoading, setIsLoading] = useToggle(false);
  const [open, setOpen] = useToggle(false);

  function fixedBiasTextValueHandler(value) {
    setFixedBiasTextValue(value);
  }

  async function getFixedBiasText() {
    setIsLoading(true);
    try {
      fixedBiasTextValueHandler("");

      let output_text = "";

      const data = await HttpPostRequest(
        {
          input_text: optimizedText,
          biases: biasesToHighlight,
        },
        "bias_resolution"
      );

      if (data.status === "success") {
        output_text = data.data.output_text;
      } else {
        console.log("API error fixing biases: ", data);
        ToastNotification(
          "Something went wrong while fixing biases. Please try again."
        );
      }
      if (output_text === optimizedText) {
        ToastNotification("No changes were made to the text.");
      }
      fixedBiasTextValueHandler(output_text);
    } catch (error) {
      console.log("Error fixing biases: ", error);
      ToastNotification(
        "Something went wrong while fixing biases. Please try again."
      );
    }
    setIsLoading(false);
  }

  const onSave = () => {
    if (fixedBiasTextValue === optimizedText) {
      ToastNotification("No changes were made to the text.");
    }
    optimizedTextHandler(fixedBiasTextValue);
    setOutputContextShowBiases(false);
    fixedBiasTextValueHandler("");
  };

  const onCancel = () => {
    fixedBiasTextValueHandler("");
  };

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e);
        if (e) {
          getFixedBiasText();
        }
      }}
    >
      <Dialog.Trigger asChild>
        <div>
          <ButtonTooltip text="Use AI to identify and remove recognized biases from the text. This can help to ensure that the text is fair and unbiased.">
            <button
              disabled={optimizedText === ""}
              className="whitespace-nowrap justify-center items-center flex flex-row space-x-1 text-[15px] px-1 md:px-3 py-[3px] bg-royal hover:bg-deeproyal rounded-lg text-white font-poppins"
            >
              <div className="container w-[16px] h-full flex justify-center items-center">
                <MdAutoFixHigh />
              </div>
              <p>Fix Biases</p>
            </button>
          </ButtonTooltip>
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
          className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] h-full w-full md:h-auto md:max-h-[60%] md:w-[80%] xl:w-[50%] overflow-y-auto translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white px-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] space-y-4 focus:outline-none"
        >
          <Dialog.Title className="font-poppins text-3xl text-center h-[5%] my-[10px]">
            Bias Review Changes
          </Dialog.Title>
          <div className="my-1 w-full h-[80%] font-poppins flex-col overflow-y-auto relative flex">
            {isLoading && (
              <div className="absolute h-[95%] w-full">
                <LoadingSpinner />
              </div>
            )}

            <textarea
              id="editor"
              className="z-10 w-full h-full min-h-[200px] px-2 text-[22px] font-poppins text-black bg-white border-2 border-gray2 focus:outline-none resize-none selection:bg-royal
        selection:bg-opacity-30"
              value={fixedBiasTextValue}
              onChange={(e) => fixedBiasTextValueHandler(e.target.value)}
              disabled={isLoading}
            />
          </div>
          <div className="flex justify-between">
            <ButtonTooltip text="Click to cancel.">
              <Dialog.Close asChild>
                <button
                  disabled={optimizedText === ""}
                  type="submit"
                  onClick={onCancel}
                  className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
                >
                  Cancel
                </button>
              </Dialog.Close>
            </ButtonTooltip>

            <ButtonTooltip text="Click to save changes.">
              <Dialog.Close asChild>
                <button
                  onClick={onSave}
                  disabled={fixedBiasTextValue === ""}
                  type="submit"
                  className="block h-[40px] w-25 p-2 px-5 mb-2 text-white text-[16px] font-poppins bg-royal rounded-full baseline hover:bg-deeproyal"
                >
                  Save
                </button>
              </Dialog.Close>
            </ButtonTooltip>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default BiasFixingPopup;
