import InputTabLayout from "./InputTabLayout";
import { useContext } from "react";
import BotContext from "../../context/BotContext";
import ButtonTooltip from "../ui/ButtonTooltip";
import React from "react";

function InputLayout() {
  const {
    inputMode,
    setInputMode,
    notesListHandler,
    originalTextHandler,
    isAssistantTyping,
    chatReset,
    updateToneHandler,
    updateStyleHandler,
  } = useContext(BotContext);

  const defaultTabClassName =
    "py-[5px] h-[75%] text-[17px] border-b-[1px] border-gray3";
  const selectedClassName = "rounded-t-lg py-2 h-full text-[18px]";

  function handleTabChange(value) {
    if (inputMode === "Chat") {
      chatReset();
    } else if (inputMode === "Notes") {
      notesListHandler([]);
    } else if (inputMode === "Free-Hand") {
      originalTextHandler("");
    }

    if (value === "Chat") {
      updateToneHandler("none");
      updateStyleHandler("none");
    }

    setInputMode(value);
  }

  return (
    <div className="flex flex-col w-full md:w-1/2 h-full">
      <div className="flex w-full flex-row align-bottom space-x-[1px]">
        <button
          disabled={isAssistantTyping}
          onClick={() => {
            handleTabChange("Free-Hand");
          }}
          className={`flex whitespace-nowrap mt-auto align-middle bg-gray1 px-2 md:px-5 font-poppins outline-none text-gray5 ${
            inputMode === "Free-Hand" ? selectedClassName : defaultTabClassName
          }`}
        >
          <ButtonTooltip text="Utilize the free-hand mode for hassle-free text creation. Your input will be intelligently rephrased to suit your preferred style and tone.">
            <span>Free-Hand</span>
          </ButtonTooltip>
        </button>
        <button
          disabled={isAssistantTyping}
          onClick={() => {
            handleTabChange("Notes");
          }}
          className={`flex whitespace-nowrap mt-auto align-middle bg-gray1 px-2 md:px-5 font-poppins outline-none text-gray5 ${
            inputMode === "Notes" ? selectedClassName : defaultTabClassName
          }`}
        >
          <ButtonTooltip text="Use the notes mode to input personalized notes that will be utilized to generate tailored feedback in your preferred style and tone.">
            <span>Notes</span>
          </ButtonTooltip>
        </button>
        <button
          disabled={isAssistantTyping}
          onClick={() => {
            handleTabChange("Chat");
          }}
          className={`flex whitespace-nowrap mt-auto align-middle bg-gray1 px-2 md:px-5 font-poppins outline-none text-gray5 ${
            inputMode === "Chat" ? selectedClassName : defaultTabClassName
          }`}
        >
          <ButtonTooltip text="Engage our AI assistant to get comprehensive help in producing feedback. Sit back and see it craft your feedback efficiently.">
            <span>AI Interview</span>
          </ButtonTooltip>
        </button>
      </div>
      <div className="flex h-full w-full">
        <InputTabLayout inputMode={inputMode} />
      </div>
    </div>
  );
}

export default InputLayout;
