import { MdDone, MdArrowDropDown } from "react-icons/md";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import ButtonTooltip from "../ui/ButtonTooltip";
import React from "react";
import PropTypes from "prop-types";
import { HttpPostRequest } from "../../helpers/HttpPostRequest";
import { useEffect, useContext, useState } from "react";
import ReviewContext from "../../context/ReviewContext";

function TemplateTypeDropdown() {
  const { templateDetailsHandler, copilotLoadingHandler } =
    useContext(ReviewContext);

  const [templateName, setTemplateName] = useState("Template Library");
  const [templatesList, setTemplatesList] = useState();

  useEffect(() => {
    const getTemplatesList = async () => {
      try {
        const data = await HttpPostRequest({}, "get_review_templates_list");

        if (data.status === "success") {
          setTemplatesList(data.data.review_templates);
        } else {
          setTemplatesList([]);
          console.log("Error getting templates");
        }
      } catch {
        setTemplatesList([]);
        console.log("Something went wrong when getting templates");
      }
    };

    getTemplatesList();
  }, []);

  async function getTemplateDetails(templateId) {
    try {
      copilotLoadingHandler(true);

      const data = await HttpPostRequest(
        { template_id: templateId },
        "get_review_template_details"
      );

      if (data.status === "success") {
        templateDetailsHandler(data.data.template);
      } else {
        console.log("Error getting template details");
      }
    } catch {
      console.log("Something went wrong when getting template details");
    }
    copilotLoadingHandler(false);
  }

  return (
    <div className="min-w-full flex flex-row items-center justify-center space-x-[2px]">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <div className="min-w-full">
            <ButtonTooltip text="Select a review template to get started.">
              <button className="flex-row min-w-full capitalize justify-between text-white font-poppins bg-royal hover:bg-deeproyal focus:ring-1 focus:outline-none focus:ring-deeproyal font-medium rounded-lg text-[19px] px-2 2xl:px-4 py-[3px] text-center inline-flex items-center">
                {templateName}
                <MdArrowDropDown />
              </button>
            </ButtonTooltip>
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="min-w-full bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform]"
            sideOffset={5}
          >
            <DropdownMenu.RadioGroup
              value={templateName}
              onValueChange={(t) => {
                getTemplateDetails(t.id);
                setTemplateName(t.name);
              }}
            >
              {templatesList ? (
                templatesList.length > 0 &&
                templatesList.map((template) => (
                  <RadioItem
                    key={template.id}
                    id={template.id}
                    name={template.name}
                  />
                ))
              ) : (
                <RadioItem
                  id={"loading_templates"}
                  name="Loading Templates..."
                  disabled={true}
                />
              )}

              <RadioItem
                id={"custom_template"}
                name="Import Template"
                disabled={true}
              />
            </DropdownMenu.RadioGroup>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
}

function RadioItem({ id, name, disabled = false }) {
  return (
    <DropdownMenu.RadioItem
      className={`text-[18px] capitalize leading-none text-poppins rounded-[3px] flex items-center hover:bg-royal hover:text-white h-[25px] px-[5px] relative pl-[25px] select-none outline-none ${
        !disabled && "cursor-pointer"
      }`}
      value={{ id: id, name: name }}
      disabled={disabled}
    >
      <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
        <MdDone />
      </DropdownMenu.ItemIndicator>
      {name}
    </DropdownMenu.RadioItem>
  );
}

RadioItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default TemplateTypeDropdown;
