import React from "react";

export function HighlightWords(text, words, style) {
  // console.log(`Text: ${text} Words: ${words} Style: ${style}`);
  const regex = new RegExp(`(${words.join("|")})`, "gi");

  const parts = text.split(regex);

  const highlighted = parts.map((part, index) => {
    words = words.map((word) => word.toLowerCase());
    if (words.includes(part.toLowerCase())) {
      return (
        <span key={index} className={`${style} text-black`}>
          {part}
        </span>
      );
    }
    return part;
  });
  return highlighted;
}
