import { useContext } from "react";
import BotContext from "../../context/BotContext";
import React from "react";
import { MdDone, MdArrowDropDown } from "react-icons/md";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import ButtonTooltip from "../ui/ButtonTooltip";
import PropTypes from "prop-types";

function ToneDropdown({ tone, updateTone }) {
  const { inputMode } = useContext(BotContext);

  if (inputMode === "Chat") {
    return (
      <div className="flex flex-col items-center justify-center">
        <p className="py-1 md:pt-2 md:pb-1 text-gray5 text-[18px] font-medium">
          Tone
        </p>

        <div className="flex flex-row items-center justify-center space-x-[2px] opacity-90">
          <div>
            <ButtonTooltip text="Tone changes are not allowed in chat mode.">
              <button
                disabled={inputMode === "Chat"}
                className="flex-row min-w-[100px] 2xl:min-w-[140px] capitalize justify-between text-white font-poppins bg-royal hover:bg-deeproyal focus:ring-1 focus:outline-none focus:ring-deeproyal font-medium rounded-lg text-[15px] px-2 2xl:px-4 py-[3px] text-center inline-flex items-center"
              >
                {tone}
                <MdArrowDropDown />
              </button>
            </ButtonTooltip>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center">
        <p className="py-1 md:pt-2 md:pb-1 text-gray5 text-[18px] font-medium">
          Tone
        </p>

        <div className="flex flex-row items-center justify-center space-x-[2px]">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <div>
                <ButtonTooltip text="Choose a tone: 'Negative' for pessimistic, 'Neutral' for unbiased, 'Positive' for optimistic, or 'None' to keep the tone the same.">
                  <button
                    disabled={inputMode === "Chat"}
                    className="flex-row min-w-[100px] 2xl:min-w-[140px] capitalize justify-between text-white font-poppins bg-royal hover:bg-deeproyal focus:ring-1 focus:outline-none focus:ring-deeproyal font-medium rounded-lg text-[15px] px-2 2xl:px-4 py-[3px] text-center inline-flex items-center"
                  >
                    {tone}
                    <MdArrowDropDown />
                  </button>
                </ButtonTooltip>
              </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="min-w-[100px] 2xl:min-w-[150px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform]"
                sideOffset={5}
              >
                <DropdownMenu.RadioGroup
                  value={tone}
                  onValueChange={updateTone}
                >
                  {inputMode === "Free-Hand" && <RadioItem value="none" />}
                  <RadioItem value="negative" />
                  <RadioItem value="neutral" />
                  <RadioItem value="positive" />
                </DropdownMenu.RadioGroup>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </div>
      </div>
    );
  }
}

ToneDropdown.propTypes = {
  tone: PropTypes.string.isRequired,
  updateTone: PropTypes.func.isRequired,
};

function RadioItem({ value }) {
  return (
    <DropdownMenu.RadioItem
      className="text-[15px] capitalize leading-none text-poppins rounded-[3px] flex items-center hover:bg-royal hover:text-white h-[25px] px-[5px] relative pl-[25px] select-none outline-none"
      value={value}
    >
      <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
        <MdDone />
      </DropdownMenu.ItemIndicator>
      {value}
    </DropdownMenu.RadioItem>
  );
}

RadioItem.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ToneDropdown;
