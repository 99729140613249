import React from "react";
import PropTypes from "prop-types";

function OutputTextLens({
  highlightedText,
  overlayRef,
  topPosition,
  leftPosition,
  width,
  textColor,
}) {
  return (
    <div
      ref={overlayRef}
      //TODO replace inline style with tailwindcss
      style={{
        height: "100%",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        position: "absolute",
        pointerEvents: "none",
        wordBreak: "normal",
        top: topPosition,
        left: leftPosition,
        width: width,
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        zIndex: "0",
        color: textColor,
      }}
    >
      {highlightedText}
    </div>
  );
}

OutputTextLens.propTypes = {
  highlightedText: PropTypes.any.isRequired,
  overlayRef: PropTypes.object.isRequired,
  topPosition: PropTypes.number.isRequired,
  leftPosition: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default OutputTextLens;
